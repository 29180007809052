<div class="place-holding"></div>
<div class="box-1">
    <div class="headline-1">
        <p>定价计划</p>
        <p>目前仅支持在线充值，更多定价计划可联系我们<br>获得专属定制。</p>
    </div>
    <div class="headline-2">
        <div class="payment-card">
            <p class="payment-card-title">在线充值</p>
            <mat-divider></mat-divider>
            <div class="payment-list">
                <div class="payment-item">
                    <div class="payment-label">
                        <p>充值金额：</p>
                        <p>(H币)</p>
                    </div>
                    <div class="payment-input">
                        <app-radio-group [options]="productRadioOptions" [selectedIndex]="selectedProductIndex"
                            [template]="productRadioTemplate"
                            (selectionChange)="onProductSelectionChange($event.index, $event.value)">
                        </app-radio-group>

                        <app-radio-group [options]="paymentRadioOptions" [selectedIndex]="selectedPaymentIndex"
                            [template]="paymentRaidoTemplate"
                            (selectionChange)="onPaymentSelectionChange($event.index, $event.value)">
                        </app-radio-group>
                    </div>
                </div>
                <div class="payment-tips">
                    <ng-container [ngSwitch]="selectedPaymentIndex">
                        <ng-container *ngSwitchCase="0">
                            <app-wechat [cart]="cart"></app-wechat>
                        </ng-container>
                        <ng-container *ngSwitchCase="1">
                            <app-ali [cart]="cart"></app-ali>
                        </ng-container>
                        <ng-container *ngSwitchCase="2">
                            <app-paypal [cart]="cart"></app-paypal>
                        </ng-container>
                        <ng-container *ngSwitchCase="3">
                            <button class="buying-button" mat-raised-button (click)="payDirectly()"
                                [disabled]="loading||selectedProduct.type==1">
                                <ng-container *ngIf="loading">
                                    <mat-spinner [diameter]="20"></mat-spinner>
                                </ng-container>
                                <ng-container *ngIf="!loading">
                                    <span>立即购买</span>
                                </ng-container>
                            </button>
                        </ng-container>
                    </ng-container>

                    <p>请支付：{{(selectedProduct.value||1) * selectedProduct.quantity * selectedProduct.price}}元</p>
                    <section>
                        <mat-checkbox [(ngModel)]="checked">
                            <span>我已阅读并同意 </span>
                        </mat-checkbox>
                        <span class="charge-agreement"> H币充值用户协议</span>
                    </section>
                </div>
            </div>
        </div>
        <div class="payment-intro">
            <div class="payment-intro-content">
                <div class="h-coin-icon">
                    <img src="../../assets/images/h-coin.svg" alt="">
                </div>
                <p>H币</p>
                <p>eprint.ink</p>
                <p>100H币/元</p>
                <p>H币可以用来购买华瀚科技所有产品的使用权益和服务，不能兑换现金。</p>
                <button class="contact-button" mat-raised-button routerLink="/contact">联系我们</button>
            </div>
            <div>
                <img src="../../assets/images/bill.svg" alt="">
            </div>
            <div class="circle-bg"></div>
        </div>
    </div>
</div>

<ng-template #productRadioTemplate let-option>
    <div class="flex vertical-center price-select-item" *ngIf="!option.isCustom">
        <span>{{ option.label }}</span>
        <div class="dashed-line"></div>
        <div class="price-group">
            <img src="../../assets/images/h-coin.svg" alt="">
            <ng-container *ngIf="option.type==1">
                <p>{{ option.value * option.quantity }}</p>
            </ng-container>
            <ng-container *ngIf="option.type==2">
                <p>{{ option.value * option.quantity * option.price * 100 }}</p>
            </ng-container>
        </div>
    </div>

    <div class="flex vertical-center price-select-item" *ngIf="option.isCustom">
        <div class="payment-input-item">
            <span>{{ option.label }} </span>
            <input matInput type="text" [(ngModel)]="option.value"
                (ngModelChange)="onCustomPriceInputChange(option)" [max]="9999" [min]="1" placeholder="其他整数金额">
        </div>
        <div class="dashed-line"></div>
        <div class="price-group">
            <img src="../../assets/images/h-coin.svg" alt="">
            <p *ngIf="option.value">{{ option.value * option.quantity }}</p>
        </div>
    </div>
</ng-template>

<ng-template #paymentRaidoTemplate let-option>
    <div class="flex payment-select-item" *ngIf="!option.isCustom">
        <img [src]="'../../assets/images/'+option.icon+'.svg'" alt="">
        <span>{{ option.label }}</span>
    </div>
</ng-template>