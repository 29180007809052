import { Component, EventEmitter, Output, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog'; // 引入 MatDialogRef

import { ApiService, SnackbarService, StorageService, UserInfoService } from 'src/services';
import { Res } from 'src/models';
import { AuthComponent } from '../auth.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  hide = signal(true);
  @Output() switchPage = new EventEmitter<string>();
  loading: boolean = false;

  // 将所有 FormControl 编组为 FormGroup
  loginForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6)
    ]),
    agreeToPrivacy: new FormControl(true, [
      Validators.requiredTrue  // 必须为 true 才会通过验证
    ]),
    rememberMe: new FormControl(true) // 新增
  });

  constructor(
    private apiService: ApiService,
    private snackbarService: SnackbarService,
    private storage: StorageService,
    private dialogRef: MatDialogRef<AuthComponent>, // 注入 MatDialogRef
    private userInfoService: UserInfoService
  ) { }

  ngOnInit() { // 修改：实现 OnInit 接口
    const savedEmail = this.storage.getItem('email'); // 修改：从 StorageService 获取已保存的邮箱

    if (savedEmail) {
      this.loginForm.patchValue({ email: savedEmail });
    }
  }

  // 提交时统一检查表单的有效性
  async login() {
    if (!this.loginForm.valid) return;
    const { email, password, rememberMe } = this.loginForm.value;

    const body = {
      contact_info: email,
      password: password,
      platform_id: 1
    }
    await this.withLoading(async () => {
      let res: Res = await this.apiService["login"]({ ...body });
      if (res.status !== 'success') throw res;
      this.snackbarService.open("登录成功"); // 使用服务
      this.storage.setItem('token', res.result);
      this.storage.setItem('qt-v0-HuaHan-Instrument-Web/token', res.result);
      await this.userInfoService.fetchAndSetUserInfo();
      res = await this.apiService["getUserAssets"]();
      if (res.status !== 'success') throw res;
      this.storage.setItem('userAssets', res.result)
      this.resetForm(this.loginForm);

      if (rememberMe && email) {
        this.storage.setItem('email', email); // 修改：使用 StorageService 保存邮箱
      } else {
        this.storage.removeItem('email'); // 修改：如果未选中，移除邮箱
      }

      this.dialogRef.close(); // 登录成功后关闭对话框
    });
  }

  resetForm(form: FormGroup) {
    form.reset();
    Object.keys(form.controls).forEach(control => {
      const ctrl = form.get(control);
      ctrl?.markAsPristine();
      ctrl?.markAsUntouched();
      ctrl?.setErrors(null);  // 清除所有错误
    });
  }

  switchToRegister() {
    this.switchPage.emit('register');
  }

  // 切换密码显示/隐藏
  passwordVisible(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

  async withLoading(asyncFunc: () => Promise<any>) {
    try {
      this.loading = true;
      await asyncFunc();
    } catch (error: any) {
      this.snackbarService.open("something went wrong!"); // 使用服务
    } finally {
      this.loading = false;
    }
  }

  openPrivacyPage(){
    window.open("/privacy", "_blank")
  }

}
