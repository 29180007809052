import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { Res } from 'src/models';
import { ApiService, UtilService, WebSocketService, SnackbarService, StorageService } from 'src/services';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements AfterViewInit {

  state: string = "";
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AuthComponent>,
    private webSocketService: WebSocketService,
    private utilService: UtilService,
    private apiService: ApiService,
    private snackbarService: SnackbarService,
    private storage: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.state = utilService.generateRandomString();
  }

  currentPage: string = 'login';

  setCurrentPage(page: string) {
    this.currentPage = page;
  }

  ngAfterViewInit() {
    const overlayBackdrop = document.querySelector('.cdk-overlay-pane');
    if (overlayBackdrop) {
      overlayBackdrop.addEventListener('click', (event: any) => {
        if (event.target.classList.contains('cdk-overlay-pane')) {
          this.dialogRef.close(); // 关闭弹窗
        }
      });
    }
  }

  private codeGotSubscription?: Subscription;

  ngOnDestroy() {
    this.disconnect();
  }

  disconnect() {
    if (!this.codeGotSubscription) return;
    this.codeGotSubscription.unsubscribe();
    this.webSocketService.disconnectSocket();
  }

  wxlogin() {

    const APPID = "wx5aa70a070b23fec8";
    const REDIRECT_URI = encodeURIComponent("https://eprint.ink/oauth/authorized/wechat");
    const SCOPE = "snsapi_login";

    // const url = `http://localhost:4200/oauth/authorized/wechat?code=091ZKu1w3AxhP33LT61w3EY5pq3ZKu1G&state=${this.state}`
    const url = `https://open.weixin.qq.com/connect/qrconnect?appid=${APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=${SCOPE}&state=${this.state}#wechat_redirect`

    const width = 500;
    const height = 600;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const popup = window.open(
      url,
      'WeChatLogin',
      `width=${width},height=${height},left=${left},top=${top},toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no`
    );
    if(popup) popup.focus();

    this.webSocketService.connectSocket();
    this.webSocketService.clientRequest(this.state);

    this.codeGotSubscription = this.webSocketService.onCodeGot().subscribe(
      async (data) => {
        const { code } = data;
        this.webSocketService.sendRequest("closeWindow", this.state, code);
        await this.withLoading(async () => {
          const body = { code: code, platform_id: 1 };
          const res: Res = await this.apiService["wxLogin"](body);
          if (res.status !== 'success') throw res;
          this.snackbarService.open("登录成功"); // 使用服务
          this.storage.setItem('token', res.result);
          this.storage.setItem('qt-v0-HuaHan-Instrument-Web/token', res.result);
          window.location.reload();
        });
      }
    );
  }

  async withLoading(asyncFunc: () => Promise<any>) {
    try {
      this.loading = true;
      await asyncFunc();
    } catch (error: any) {
      console.log(error)
      this.snackbarService.open("something went wrong!"); // 使用服务
    } finally {
      this.loading = false;
    }
  }

}
