import { Injectable, NgZone } from '@angular/core';
import { ScrollDispatcher, ViewportRuler } from '@angular/cdk/scrolling';
import { throttleTime, debounceTime } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  private scrollHandlers: ((offset: number) => void)[] = [];

  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private viewportRuler: ViewportRuler,
    private ngZone: NgZone  // 注入 NgZone
  ) {
    this.scrollDispatcher.scrolled().pipe(throttleTime(100),debounceTime(100)).subscribe(() => {
      const offset = this.viewportRuler.getViewportScrollPosition().top;
      this.scrollHandlers.forEach(handler => handler(offset));
      //当注册多个scroll服务时候使用以下的更新检测策略，防止header没有更新
      this.ngZone.run(() => {  // 使用 ngZone.run 来执行处理函数
        this.scrollHandlers.forEach(handler => handler(offset));
      });
    });
  }

  registerScrollHandler(handler: (offset: number) => void) {
    this.scrollHandlers.push(handler);
  }

  unregisterScrollHandler(handler: (offset: number) => void) {
    const index = this.scrollHandlers.indexOf(handler);
    if (index > -1) {
      this.scrollHandlers.splice(index, 1);
    }
  }
}
