<div class="placeholder-back"></div>

<div class="help">

    <!-- <ng-container *ngIf="screenType=='desktop'; else notMobile">
        <div class="circle-bg circle-bg-left"></div>
        <div class="circle-bg circle-bg-right"></div>
    </ng-container>
    <ng-template #notMobile>
        <div class="gradient-bg"></div>
    </ng-template> -->

    <div class="help-header center-content">
        <p class="help-title">华瀚帮助中心</p>
        <!-- <div class="help-tab flex">
            <div class="help-tab-item">
                <div class="software-logo software-c-logo"></div>
                <p>青华</p>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="help-tab-item">
                <div class="software-logo software-m-logo"></div>
                <p>丹书</p>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="help-tab-item">
                <div class="software-logo software-y-logo"></div>
                <p>丰芒</p>
            </div>
            <mat-divider [vertical]="true"></mat-divider>
            <div class="help-tab-item">
                <div class="software-logo software-k-logo"></div>
                <p>玄玉</p>
            </div>
        </div> -->
    </div>

    <div class="help-video center-content">
        <mat-toolbar class="video-toolbar">
            <span>青华 RIP</span>
            <span *ngIf="screenType=='desktop'">-</span>
            <span>极速、舒适、强大的现代RIP工具</span>
        </mat-toolbar>

        <div class="video-area">
            <div class="video-card">
                <div class="video-wrapper">
                    <img *ngIf="!isPlaying" class="cover-image" src="../../assets/images/porcelain/video-poster.png"
                        alt="封面图片" />
                    <video #videoPlayer controls [hidden]="!isPlaying">
                        <source src="../../assets/videos/porcelain/advertise.mp4" type="video/mp4">
                        你的浏览器不支持视频标签。
                    </video>
                    <button class="play-button" *ngIf="!isPlaying" (click)="playVideo()">
                        <img src="../../assets/images/porcelain/triangle.svg" alt="Play Icon" class="play-icon" />
                    </button>
                </div>
            </div>

            <div class="video-content">
                <img src="../../assets/images/video-content.svg" alt="">
                <div class="video-content-text">
                    <p>了解青华</p>
                    <p>在现代印刷行业中，高效、精确的排版和色彩管理对于确保最终印刷质量至关重要。而青华（Porcelain）正是为了解决这一需求而设计的，它将排版、图像处理和色彩管理整合到一个统一的平台中，极大地提升了工作流程的效率和精确度。
                    </p>
                </div>
            </div>
        </div>


    </div>

    <div class="guidance center-content">
        <mat-toolbar>
            <span>用户指南</span>
            <span class="example-spacer"></span>
            <button mat-icon-button (click)="navigateToLink(guidanceWebsitePrefix)">
                <mat-icon>arrow_forward</mat-icon>
            </button>
        </mat-toolbar>
        <div class="guidance-area">

            <ng-container *ngFor="let guidanceItem of guidanceItemList;">
                <div class="guidance-item">
                    <div class="guidance-item-logo" [ngClass]="guidanceItem.iconClass"></div>
                    <div class="guidance-item-text">
                        <a>{{guidanceItem.title}}</a>
                        <ng-container *ngIf="screenType=='desktop';else pageCount">
                            <ng-container *ngFor="let subTitle of guidanceItem.subTitle;">
                                <a
                                    (click)="navigateToLink(guidanceWebsitePrefix+guidanceItem.link+subTitle.link)">{{subTitle.title}}</a>
                            </ng-container>
                        </ng-container>
                        <ng-template #pageCount>
                            <p>{{guidanceItem.subTitle.length}}篇</p>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="problem flex">
        <div class="problem-nav-area flex-1">
            <p>可能遇到的问题</p>
            <p>没有相同的问题？点击下面按钮查看所有问题。</p>
            <button mat-raised-button (click)="navigateToLink(problemListLink)">查看所有问题</button>
        </div>
        <div class="flex-1 problem-panel-area">
            <app-expansion-panel>
                <div panel-title>排版方式该怎么选择？</div>
                <div panel-content>
                    三种排版方式，针对您的需求来选择合适的方式可以大大节材省料您的需求来选择合适的方式您的需求来
                    选择合适的方式您的需求来选择合适的方式来选择合适的方式您的需求来选择合适的方式。
                </div>
            </app-expansion-panel>
            <app-expansion-panel>
                <div panel-title>基础版和专业版有什么区别？</div>
                <div panel-content>
                    三种排版方式，针对您的需求来选择合适的方式可以大大节材省料您的需求来选择合适的方式您的需求来
                    选择合适的方式您的需求来选择合适的方式来选择合适的方式您的需求来选择合适的方式。
                </div>
            </app-expansion-panel>
            <app-expansion-panel>
                <div panel-title>青华RIP排版软件的优势是什么？</div>
                <div panel-content>
                    三种排版方式，针对您的需求来选择合适的方式可以大大节材省料您的需求来选择合适的方式您的需求来
                    选择合适的方式您的需求来选择合适的方式来选择合适的方式您的需求来选择合适的方式。
                </div>
            </app-expansion-panel>
            <app-expansion-panel>
                <div panel-title>文件默认格式是什么？</div>
                <div panel-content>
                    三种排版方式，针对您的需求来选择合适的方式可以大大节材省料您的需求来选择合适的方式您的需求来
                    选择合适的方式您的需求来选择合适的方式来选择合适的方式您的需求来选择合适的方式。
                </div>
            </app-expansion-panel>
            <app-expansion-panel>
                <div panel-title>如何成为专业版用户？</div>
                <div panel-content>
                    三种排版方式，针对您的需求来选择合适的方式可以大大节材省料您的需求来选择合适的方式您的需求来
                    选择合适的方式您的需求来选择合适的方式来选择合适的方式您的需求来选择合适的方式。
                </div>
            </app-expansion-panel>
        </div>
    </div>

</div>