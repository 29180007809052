import { Component } from '@angular/core';

@Component({
  selector: 'app-qt-webasm',
  templateUrl: './qt-webasm.component.html',
  styleUrls: ['./qt-webasm.component.scss']
})
export class QtWebasmComponent {

}
