import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { OverlayService, LangService } from 'src/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'HUAHAN TECHNOLOGY';
  loading: boolean = false;

  constructor(
    private overlayService: OverlayService,
    private titleService: Title,
    private translate: TranslateService,
    private langService: LangService, // 注入 LangService
  ) {
    this.langService.transLateInit(); // 调用 LangService 的初始化方法
    this.translate.onLangChange.subscribe(event => {
      this.langService.translate('SITE_TITLE').subscribe(res => {
        this.titleService.setTitle(res)
      })
    });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.overlayService.overlayRef?.hasAttached()) this.overlayService.handleClickOutside(event);
  }

  ngAfterViewInit() {
    this.langService.translate('SITE_TITLE').subscribe(res => {
      this.titleService.setTitle(res)
    })
  }

}
