<mat-card appearance="outlined" class="personal">
    <mat-card-content class="padding-16 personal-center">
        <div class="personal-info">
            <img [src]="userinfo?.avatar||'https://eprint.ink/files/website/avatar/female-1.png'" alt="" class="avatar">
            <p>{{userinfo?.contact_person}}</p>
            <p>H币：{{userCoin}}</p>

        </div>
        <div class="charge-area">
            <div>
                <p>充值H币</p>
                <p>畅享权益</p>
            </div>
            <div>
                <button mat-raised-button routerLink="/price" (click)="closeOverlay()">立即充值</button>
            </div>
        </div>
        <mat-divider class="divider"></mat-divider>

        <mat-nav-list>
            <mat-list-item>
                <div class="profile-background flex" routerLink="/personal" (click)="closeOverlay()">
                    <div class="profile-group flex">
                        <div class="profile-icon"></div>
                        <div>
                            <a>{{'用户中心'}}</a>
                        </div>
                    </div>
                    <mat-icon class="icon-right">chevron_right</mat-icon>
                </div>
            </mat-list-item>
            <mat-list-item>
                <div class="profile-background flex" routerLink="/" (click)="logout();">
                    <div class="profile-group flex">
                        <div class="exit-icon"></div>
                        <div>
                            <a>{{'退出登录'}}</a>
                        </div>
                    </div>
                    <mat-icon class="icon-right">chevron_right</mat-icon>
                </div>
            </mat-list-item>
        </mat-nav-list>

    </mat-card-content>
</mat-card>