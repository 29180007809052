import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

class LocalStorage implements Storage {
  [name: string]: any;
  readonly length!: number;
  clear(): void { }
  getItem(key: string): string | null { return null; }
  key(index: number): string | null { return null; }
  removeItem(key: string): void { }
  setItem(key: string, value: string): void { }
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private storage: Storage;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.storage = new LocalStorage();
    if (isPlatformBrowser(this.platformId)) {
      this.storage = window.localStorage;
    }
  }

  [name: string]: any;

  length!: number;

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    const storedItem = this.storage.getItem(key);
    if (!storedItem) return null;
    try {
      const item = JSON.parse(storedItem);
      // 如果解析成功，且返回的是一个对象，则认为是一个有效的 JSON 字符串
      const now = new Date();
      if (item.expiry && item.expiry < now.getTime()) {
        this.storage.removeItem(key);
        return null;
      }
      return item.value;
    } catch (err) {
      return storedItem;
    }
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    const expireInSeconds = 24 * 60 * 60; // 24小时
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + expireInSeconds * 1000, // Convert seconds to milliseconds
    };
    return this.storage.setItem(key, JSON.stringify(item));
  }
  
}
