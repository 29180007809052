<div class="summary vertical-center flex">

    <div class="temp-back"></div>

    <div class="summary-title">
        <div class="headline-1">
            <p class="text-white">青华RIP</p>
            <p class="text-white">极速、舒适、强大的现代RIP工具</p>
        </div>
        <p class="headline-4">
            <button class="contact-button" mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)"
                (click)="playVideo($event)">
                <div class="flex-button">
                    <span>观看视频</span>
                </div>
            </button>
        </p>

        <div class="headline-5">
            <mat-card>
                <div class="video-cover" (click)="playVideo($event)">
                    <ng-container *ngIf="!isPlaying">
                        <img mat-card-image src="../../assets/images/video-cover.png">
                    </ng-container>

                    <div class="welcome-video-controls-container">
                        <button class="welcome-video-control-item" data-inline-media-control="PlayPause"
                            data-aria-playing="Pause video showcasing the lineup of Mac products"
                            data-aria-paused="Play video showcasing the lineup of Mac products"
                            aria-label="Play video showcasing the lineup of Mac products"
                            data-analytics-title-pause="pause hero video"
                            data-analytics-click-pause="prop3:pause hero video"
                            data-analytics-title-play="play hero video"
                            data-analytics-click-play="prop3:play hero video" data-analytics-intrapage-link=""
                            data-analytics-click="prop3:play hero video" data-analytics-title="play hero video">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                class="control-centered-small-icon">
                                <g *ngIf="isPlaying" class="control-icon-pause">
                                    <rect width="4.5" height="14" x="3.75" y="3" rx="1.5"></rect>
                                    <rect width="4.5" height="14" x="11.75" y="3" rx="1.5"></rect>
                                </g>
                                <path *ngIf="!isPlaying" class="control-icon-play"
                                    d="M5 15.25V4.77a1.44 1.44 0 0 1 1.44-1.62 1.86 1.86 0 0 1 1.11.31l8.53 5c.76.44 1.17.8 1.17 1.51s-.41 1.07-1.17 1.51l-8.53 5a1.86 1.86 0 0 1-1.11.31A1.42 1.42 0 0 1 5 15.25Z">
                                </path>
                            </svg>
                        </button>
                    </div>

                    <video #videoPlayer [hidden]="!isPlaying">
                        <source src="../../assets/videos/porcelain/advertise.mp4" type="video/mp4">
                        你的浏览器不支持视频标签。
                    </video>
                </div>
            </mat-card>
        </div>


    </div>

    <ng-container *ngIf="screenType=='desktop'; else notMobile">
        <div class="circle-bg circle-bg-left"></div>
        <div class="circle-bg circle-bg-right"></div>
    </ng-container>
    <ng-template #notMobile>
        <div class="gradient-bg"></div>
    </ng-template>

    <div class="summary-title">
        <p class="headline-1">
            <span class="gradient-text">一站式</span><span>打印工具集</span>
        </p>
        <div class="headline-2 vertical-center flex">
            <div class="line"></div>
            <div class="dot"></div>
        </div>
        <p class="headline-3">
            无论您是需要高效率的排版、提高色彩表现力、PDF的编辑还是可变数据的打印，ePrint.ink都为您提供了最佳工具。
        </p>
        <!-- <p class="headline-4">
            <button class="font-size-large contact-button" mat-raised-button routerLink="/contact">联系我们</button>
        </p> -->
    </div>

    <div class="software flex">

        <app-software-card [headlineOne]="'青华'" [headlineTwo]="'喷绘RIP软件'" [headlineThree]="'详细了解 青华'"
            [buttonText]="'即将上线，敬请期待'" [buttonUseable]="false" [logoClass]="'soft-c-logo'"
            (buttonClicked)="handleButtonClick()"></app-software-card>

        <app-software-card [headlineOne]="'丹书'" [headlineTwo]="'可变数据打印软件'" [buttonText]="'即将上线，敬请期待'"
            [buttonUseable]="false" [logoClass]="'soft-m-logo'"></app-software-card>

        <app-software-card [headlineOne]="'丰芒'" [headlineTwo]="'全自动排版软件'" [buttonText]="'即将上线，敬请期待'"
            [buttonUseable]="false" [logoClass]="'soft-y-logo'"></app-software-card>

        <app-software-card [headlineOne]="'玄玉'" [headlineTwo]="'二维码设计软件'" [buttonText]="'即将上线，敬请期待'"
            [buttonUseable]="false" [logoClass]="'soft-k-logo'"></app-software-card>

    </div>

</div>

<div class="intro">
    <div class="flex intro-title">
        <div class="line left-line"></div>
        <p>产品介绍</p>
        <div class="line right-line"></div>
    </div>

    <div class="intro-area flex">
        <div class="gradient-box gradient-box-c">
            <div class="interface-image interface-image-c">
            </div>
        </div>
        <div class="intro-content">
            <div class="intro-textarea">
                <p class="headline-1">青华 网页版</p>
                <p class="headline-2">适用于广告打印、T恤印花等应用的RIP软件，提供了手动和自动排版、自动构建专色通道和色彩管理等实用功能。</p>
                <div class="line"></div>
                <div class="headline-3 flex">
                    <!-- <p class="with-arrow">开始免费使用 青华</p> -->
                    <p class="with-arrow" (click)="handleButtonClick()">详细了解 青华</p>
                </div>
            </div>
        </div>
    </div>

    <div class="intro-area flex">
        <div class="gradient-box gradient-box-m">
            <div class="interface-image interface-image-m">
            </div>
        </div>
        <div class="intro-content">
            <div class="intro-textarea">
                <p class="headline-1">丹书 网页版</p>
                <p>适用于包装、印刷行业的设计、排版和打印的综合性RIP软件，支持多页PDF、图形设计、文字打印优化以及可变数据打印等。</p>
                <div class="line"></div>
                <div class="headline-3 flex">
                    <p class="with-arrow">即将上线，敬请期待</p>
                    <!-- <p class="with-arrow">详细了解 丹书</p> -->
                </div>
            </div>
        </div>
    </div>

    <div class="intro-area flex">
        <div class="gradient-box gradient-box-y">
            <div class="interface-image interface-image-y">
            </div>
        </div>
        <div class="intro-content">
            <div class="intro-textarea">
                <p class="headline-1">丰芒 网页版</p>
                <p>适用于广告、包装等行业的自动排版工作流软件，是业界速度最快的异形套排解决方案，自动识别轮廓、自动优化切割路径、自动排版、自动输出等，实现了无人值守工作流。</p>
                <div class="line"></div>
                <div class="headline-3 flex">
                    <p class="with-arrow">即将上线，敬请期待</p>
                    <!-- <p class="with-arrow">详细了解 丰芒</p> -->
                </div>
            </div>
        </div>
    </div>

    <div class="intro-area flex">
        <div class="gradient-box gradient-box-k">
            <div class="interface-image interface-image-k">
            </div>
        </div>
        <div class="intro-content ">
            <div class="intro-textarea">
                <p class="headline-1">玄玉 网页版</p>
                <p>适用于包装、电商等行业条形码、二维码等可变数据的设计与打印软件，支持多种数据源的排序和过滤，并集成了强大的艺术二维码设计功能。</p>
                <div class="line"></div>
                <div class="headline-3 flex">
                    <p class="with-arrow">即将上线，敬请期待</p>
                    <!-- <p class="with-arrow">详细了解 玄玉</p> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="technical vertical-center flex">
    <div class="tech-banner vertical-center flex">
        <p>技术支持</p>
    </div>
    <div class="flex tech-title">
        <div class="line left-line"></div>
        <p>为您提供最佳解决方案</p>
        <div class="line right-line"></div>
    </div>

    <div class="flex tech-content">
        <div class="advantage vertical-center flex">
            <div class="advantage-image advantage-image-1"></div>
            <div class="advantage-text">高度安全</div>
            <div class="advantage-detail">对于涉及行业合规要求的用户，提供合规性咨询和技术支持，确保软件满足相关法律法规的要求。</div>
        </div>
        <div class="advantage vertical-center flex">
            <div class="advantage-image advantage-image-2"></div>
            <div class="advantage-text">优质性能</div>
            <div class="advantage-detail">在高并发、高负载的情况下，提供负载均衡配置和系统扩展方案，帮助客户应对流量激增的挑战。</div>
        </div>
        <div class="advantage vertical-center flex">
            <div class="advantage-image advantage-image-3"></div>
            <div class="advantage-text">全天客服</div>
            <div class="advantage-detail">我们提供24/7全天候客户支持和技术服务，确保您在任何时候都能得到快速响应和解决方案。</div>
        </div>
    </div>

</div>

<div class="contact vertical-center flex">
    <div class="circle-bg circle-bg-left"></div>
    <div class="circle-bg circle-bg-right"></div>

    <div class="contact-content vertical-center flex">
        <p class="headline-1">准备好和我们一起发展了吗？</p>
        <p class="headline-2">我们的目标是通过与贵公司的合作，利用我们的技术力量帮助贵公司提升运营效率、产品质量和市场竞争力，实现双方的共同成长和长期成功。</p>
        <button class="contact-button" mat-raised-button routerLink="/contact">联系我们</button>
    </div>

</div>