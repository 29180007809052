import { Component, OnDestroy } from '@angular/core';
import { ScreenService } from 'src/services/cdk/screen.service';
import { Subscription } from 'rxjs';
import anime from 'animejs/lib/anime.es.js';

@Component({
  selector: 'app-sakura',
  templateUrl: './sakura.component.html',
  styleUrls: ['./sakura.component.scss']
})
export class SakuraComponent implements OnDestroy {

  private maxPetals = 30;
  private petalCount = 0;
  private container: HTMLElement | null = null;
  private subscriptions: Subscription[] = [];
  screenType: string = "desktop";
  private scaleRatio: number = 1;

  constructor(
    private screenService: ScreenService,
  ) {
  }

  ngOnInit() {
    this.container = document.querySelector('.sakura-container') as HTMLElement;
    this.subscriptions.push(
      this.screenService.onResize$.subscribe(size => {
        this.screenType = size < 1200 ? "mobile" : "desktop";
        size < 1200 ? (this.scaleRatio = 2) : (this.scaleRatio = 1)
      })
    );
    this.createTreeAnimation();
    this.createSakuraAnimation();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());

    // 停止所有动画
    anime.remove('.sakura-petal');
    // 清空容器内的花瓣元素
    if (this.container) {
      this.container.innerHTML = '';
    }
    this.container = null;
  }

  createTreeAnimation() {
    const tree = document.querySelector('.tree-1') as HTMLElement;
    tree.style.transformOrigin = 'left';
    anime({
      targets: tree,
      rotate: 3,
      duration: 5000, // 随机动画持续时间
      easing: 'easeInOutBack',
      loop: true,
      direction: 'alternate'
    });
  }

  createSakuraAnimation() {
    for (let i = 0; i < this.maxPetals; i++) {
      this.createPetal()
    }
  }

  createPetal = () => {
    if (!this.container || this.petalCount >= this.maxPetals) return;
    this.petalCount++;
    const petalIndex = anime.random(1, 6);
    const petal = document.createElement('div');
    this.petalStyleBind(petal, petalIndex);
    this.container.appendChild(petal);
    this.createAnime(petal);
  }

  petalStyleBind(petal: HTMLElement, petalIndex: number) {
    petal.classList.add('sakura-petal');
    petal.style.backgroundImage = `url('assets/images/sakura/petal-${petalIndex}.png')`;
    petal.style.width = `${Math.random() * 20 + 60}px`;
    petal.style.height = petal.style.width;
    petal.style.backgroundRepeat = "no-repeat";
    petal.style.position = "absolute";
    petal.style.zIndex = "2";
    petal.style.left = "0";
    petal.style.top = "0";
    const scale = anime.random(500, 1000) / (1000 * this.scaleRatio);
    petal.style.opacity = `${scale}`;
    petal.style.scale = `${scale}`;
    const blur = (1 - scale) * 4;
    petal.style.filter = `blur(${blur}px)`;
  }

  createAnime(petal: HTMLElement) {
    const { randomX, randomY } = this.setPosition();
    const startPoint = anime.random(-200, -100);
    const scale = petal.style.scale;
    const endPointX = Math.round(randomX / parseFloat(scale));
    const endPointY = Math.round(randomY / parseFloat(scale));
    anime({
      targets: petal,
      translateX: [startPoint, endPointX],
      translateY: [-400 - startPoint, endPointY],
      rotate: Math.random() > 0.5 ? [0, 360] : [360, 0],
      duration: anime.random(40000, 60000), // 随机动画持续时间
      delay: anime.random(0, 100000),
      easing: 'linear',
      loop: false,
      complete: () => {
        petal.remove();
        this.petalCount--;
        this.createPetal(); // 在动画完成后调度下一个花瓣
      }
    });
  }

  setPosition() {
    if (!this.container) return { randomX: 0, randomY: 0 };
    const containerWidth = this.container.clientWidth;
    const containerHeight = this.container.clientHeight;
    let randomX, randomY;
    const randomLine = Math.random() < 0.5 ? 'x' : 'y';
    if (randomLine === 'x') {
      randomX = containerWidth + 100;
      randomY = anime.random(100, containerHeight + 100);
    } else {
      randomY = containerHeight + 100;
      randomX = anime.random(100, containerWidth + 100);
    }
    if (randomX < containerWidth && randomY < containerHeight) console.log("to short")
    return { randomX, randomY };
  }

}
