<div class="contact">
    <div class="contact-header">
        <p class="contact-title">获得试用机会</p>
    </div>

    <div class="contact-content">
        <div class="contact-message">
            <form class="example-form" [formGroup]="contactForm" (ngSubmit)="submitForm()">
                <div class="loading-animation" *ngIf="loading">
                    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                </div>

                <div class="combine-form-control">
                    <mat-form-field class="example-full-width">
                        <mat-label>{{ 'Name' | translate }}</mat-label>
                        <input matInput name="name" formControlName="name">
                        <mat-error *ngIf="contactForm.get('name')?.hasError('required')">{{ 'Contact.name-required' |
                            translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-label>{{ 'CompanyName' |
                            translate }}</mat-label>
                        <input matInput name="company" formControlName="company">
                        <mat-error *ngIf="contactForm.get('company')?.hasError('required')">{{
                            'Contact.company-required' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <div class="combine-form-control">
                    <mat-form-field class="example-full-width">
                        <mat-label>{{ 'CompanyPosition' |
                            translate }}</mat-label>
                        <input matInput name="companyPosition" formControlName="companyPosition">
                        <mat-error *ngIf="contactForm.get('companyPosition')?.hasError('required')">{{
                            'Contact.position-required' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-label>{{ 'CompanyAddress' |
                            translate }}</mat-label>
                        <input matInput name="companyAddress" formControlName="companyAddress">
                        <mat-error *ngIf="contactForm.get('companyAddress')?.hasError('required')">{{
                            'Contact.address-required' | translate }}</mat-error>
                    </mat-form-field>
                </div>

                <div class="combine-form-control">
                    <mat-form-field class="example-full-width">
                        <mat-label>{{ 'Phone' | translate }}</mat-label>
                        <input matInput name="phone" formControlName="phone">
                        <mat-error *ngIf="contactForm.get('phone')?.hasError('pattern')">{{
                            'Contact.phonenumber-required' | translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-label>{{ 'Email' | translate }}</mat-label>
                        <input matInput name="email" formControlName="email">
                        <mat-error *ngIf="contactForm.get('email')?.hasError('email')">{{ 'Contact.email-required' |
                            translate }}</mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'Printer' | translate }}</mat-label>
                    <input matInput name="printer" formControlName="printer">
                    <mat-error *ngIf="contactForm.get('printer')?.hasError('required')">{{ 'Contact.printer-required' |
                        translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'Rip' | translate }}</mat-label>
                    <input matInput name="rip" formControlName="rip">
                    <mat-error *ngIf="contactForm.get('rip')?.hasError('required')">{{ 'Contact.rip-required' |
                        translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width contact-message-textarea">
                    <mat-label>{{ 'Contact.Content' | translate }}</mat-label>
                    <textarea matInput name="content" formControlName="content"
                        class="contact-message-textarea-input"></textarea>
                    <mat-error *ngIf="contactForm.get('content')?.hasError('required')">{{ 'Contact.content-required' |
                        translate }}</mat-error>
                </mat-form-field>

                <button class="font-size-large example-full-width" mat-raised-button color="primary">{{ 'submit' |
                    translate }}</button>
            </form>
        </div>
    </div>
</div>