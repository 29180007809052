<div class="login-container">
  <h1 class="company-title">欢迎来到华瀚~</h1>
  <form [formGroup]="registerForm" (ngSubmit)="register()" style="width:100%" class="login-form">

    <div class="loading-animation" *ngIf="loading">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

    <mat-form-field appearance="outline">
      <input matInput type="text" name="email" class="custom-input" formControlName="email" required autocomplete="off"
        placeholder="请输入邮箱">
      <mat-error *ngIf="registerForm.get('email')?.hasError('required')">邮箱地址是必填项</mat-error>
      <mat-error *ngIf="registerForm.get('email')?.hasError('email')">请输入有效的邮箱地址</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input matInput [type]="hide() ? 'password' : 'text'" name="password" formControlName="password"
        placeholder="请输入密码" required autocomplete="off" />
      <button matSuffix (click)="passwordVisible($event)" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide()" class="hide-password-button">
        <ng-container *ngIf="hide();else visibility_outline">
          <div class="visibility_off_outline visibility-icon"></div>
        </ng-container>
        <ng-template #visibility_outline>
          <div class="visibility_outline visibility-icon"></div>
        </ng-template>
      </button>
      <mat-error *ngIf="registerForm.get('password')?.hasError('required')">密码是必填项</mat-error>
      <mat-error *ngIf="registerForm.get('password')?.hasError('minlength')">密码长度至少为6个字符</mat-error>
    </mat-form-field>

    <div class="verification-code-container">
      <mat-form-field appearance="outline" style="flex: 1;">
        <input matInput type="text" name="verificationCode" formControlName="verificationCode" placeholder="请输入验证码"
          required autocomplete="off" />
        <mat-error *ngIf="registerForm.get('verificationCode')?.hasError('required')">验证码是必填项</mat-error>
      </mat-form-field>

      <button mat-raised-button color="primary" type="button" (click)="getVerificationCode()"
        [disabled]="isButtonDisabled">
        {{ countdown > 0 ? countdown + '秒后重新获取' : '获取验证码' }}
      </button>
    </div>


    <div class="agreement">
      <mat-checkbox matRippleDisabled="true" formControlName="agreeToPrivacy">
        <p class="agreement-check">阅读并同意</p>
      </mat-checkbox>
      <p>隐私政策</p>
      <mat-error *ngIf="registerForm.get('agreeToPrivacy')?.hasError('requiredTrue')">
        请阅读并同意隐私政策
      </mat-error>
    </div>
    <div class="button-container">
      <button mat-raised-button color="primary" type="submit" [disabled]="registerForm.invalid">使用电子邮箱注册</button>
    </div>
  </form>
  <div class="toggle-page">
    <span>没有账户？</span><a (click)="switchToLogin()">注册</a>
  </div>
</div>