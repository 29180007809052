import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { timeout } from 'rxjs/operators';

import { StorageService } from '../storage/storage.service';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import en from '@angular/common/locales/en';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  constructor(
    private translateService: TranslateService,
    private storage: StorageService
  ) {
    registerLocaleData(zh);
    registerLocaleData(en);
  }

  transLateInit() {
    this.translateService.addLangs(["en", "zh"]);
    let lang = this.storage.getItem("lang");
    let browserLang = this.translateService.getBrowserLang();
    this.translateService.setDefaultLang(lang || browserLang || 'zh');
    if(lang) this.translateService.use(lang);
  }

  changeLang(lang: string) {
    this.translateService.use(lang);
    this.storage.setItem("lang", lang);
  }

  translate(word: string) {
    return this.translateService.get(word).pipe(timeout(10000));
  }

  translateSync(word: string) {
    return this.translateService.instant(word);
  }

  get langs() {
    return this.translateService.langs;
  }

  get currentLang() {
    return this.translateService.currentLang;
  }

}
