import { Component } from '@angular/core';
import { OverlayService } from 'src/services';
import { fadeInOut } from 'src/animation/animations';

@Component({
  selector: 'app-product-dropdownmenu',
  templateUrl: './product-dropdownmenu.component.html',
  styleUrls: ['./product-dropdownmenu.component.scss'],
  animations: [fadeInOut]
})
export class ProductDropdownmenuComponent {

  constructor(
    private overlayService: OverlayService,
  ) {
  }

  navData = [
    { link: "/porcelain", headlineTextOne: "青华", headlineTextTwo: "广告打印、T恤印花等应用的RIP软件", className: "software-c-logo" },
    { link: "/", headlineTextOne: "丹书", headlineTextTwo: "PDF可变数据打印软件", className: "software-m-logo" },
    { link: "/", headlineTextOne: "丰芒", headlineTextTwo: "全自动工作流的排版工具", className: "software-y-logo" },
    { link: "/", headlineTextOne: "玄玉", headlineTextTwo: "强大的艺术二维码设计软件", className: "software-k-logo" },
  ]

  closeOverlay() {
    this.overlayService.close();
  }

}
