import { Component, ViewChild, ElementRef } from '@angular/core';
import { ScreenService } from 'src/services/cdk/screen.service'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {

  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  isPlaying: boolean = false; // 控制视频是否正在播放

  playVideo() {
    this.isPlaying = true; // 设置为正在播放
    this.videoPlayer.nativeElement.play(); // 播放视频
  }

  watchVideoLink: string = "https://eprint.gitbook.io/porcelain/"
  screenType: string = "desktop";
  private subscriptions: Subscription[] = [];
  guidanceWebsitePrefix = "https://eprint.gitbook.io/porcelain/"
  problemListLink = "https://eprint.gitbook.io/porcelain/help-and-faqs/"

  guidanceItemList = [
    {
      title: "界面说明", link: "description/", subTitle: [
        { title: "概述", link: "overview" },
        { title: "登录系统", link: "log-in-to-the-system" },
        { title: "主页屏幕", link: "home" },
      ], iconClass: "guidance-item-logo-interface"
    },
    {
      title: "文件操作", link: "file-operations/", subTitle: [
        { title: "创建文档", link: "creating-documents" },
        { title: "打开文件", link: "open-the-file" },
        { title: "还原和历史记录", link: "undo-and-redo" },
        { title: "存储和导出", link: "store-and-export" }
      ], iconClass: "guidance-item-logo-file"
    },
    {
      title: "版面设计", link: "layout",
      subTitle: [
        { title: "调整图像与视图区", link: "image-editing-and-view" },
        { title: "色彩调整", link: "make-color-adjustments" },
      ], iconClass: "guidance-item-logo-layout"
    },
    {
      title: "打印图像", link: "print-the-image", subTitle: [
        { title: "打印任务流程", link: "print-the-image" },
      ], iconClass: "guidance-item-logo-printer"
    },
    {
      title: "色彩管理", link: "color-management", subTitle: [
        { title: "使用ICC颜色配置文件", link: "using-color-profiles" },
        { title: "颜色较准", link: "color-calibration" },
      ], iconClass: "guidance-item-logo-color"
    },
    {
      title: "快捷键管理", link: "management-of-shortcut-keys", subTitle: [
        { title: "快捷键", link: "shortcut-key" },
      ], iconClass: "guidance-item-logo-shortcut"
    },
  ]

  constructor(
    private screenService: ScreenService,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.screenService.onResize$.subscribe(size => {
        this.screenType = size < 1200 ? "mobile" : "desktop";
      })
    );
  }

  navigateToLink(link: string) {
    window.open(link, '_blank');
  }

}
