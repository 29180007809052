import { Component, ViewContainerRef, ElementRef, Input, TemplateRef, Type } from '@angular/core';
import { Overlay, OverlayConfig, ConnectedPosition } from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';

import { OverlayService, ScreenService } from 'src/services'

@Component({
  selector: 'app-menu-trigger',
  templateUrl: './menu-trigger.component.html',
  styleUrls: ['./menu-trigger.component.scss']
})
export class MenuTriggerComponent {

  @Input() template?: TemplateRef<any>;
  @Input() component!: Type<any>;
  @Input() positionStrategy!: ConnectedPosition;
  @Input() name!: string;

  private config?: OverlayConfig;
  private subscriptions: Subscription[] = [];
  screenType: string = "desktop";
  private visible: boolean = false;

  constructor(
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private overlayService: OverlayService,
    private elementRef: ElementRef,
    private screenService: ScreenService,
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.screenService.onResize$.subscribe(size => {
        const screenType = this.screenType
        this.screenType = size < 1200 ? "mobile" : "desktop";
        if (screenType != this.screenType) this.overlayService.close()
      })
    );
    if (!this.template) return;
    this.subscriptions.push(
      this.overlayService.isOverlayOpen$.subscribe(overlayRefName => {
        if (this.name != overlayRefName) this.visible = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  clickControl(event: Event) {
    event.stopPropagation();
    this.visible = !this.visible;
    this.toggleOverlay(this.visible);
  }

  toggleOverlay(visible: boolean) {
    visible ? this.open() : this.close()
  }

  open() {
    if (!this.template) return;
    this.configSetting();
    this.overlayService.open(this.name, this.template, this.config, this.viewContainerRef);
  }

  close() {
    this.overlayService.close();
  }

  configSetting() {
    this.config = new OverlayConfig();
    const buttonElement = this.elementRef.nativeElement.querySelector('.btn');
    const father = new ElementRef(buttonElement);
    this.config!.positionStrategy = this.overlay.position()
      .flexibleConnectedTo(father.nativeElement).withPositions([this.positionStrategy])
  }

}
