import { Component } from '@angular/core';
import { ScrollService } from "src/services/cdk/scroll.service"

@Component({
  selector: 'app-backtop',
  templateUrl: './backtop.component.html',
  styleUrls: ['./backtop.component.scss']
})
export class BacktopComponent {

  private currentOffset: number = 0;
  private offsetDistance: number = 600;
  public showButton = false;
  private boundUpdateScrollState: (offset: number) => void;

  constructor(
    private scrollService: ScrollService,
  ) {
    this.boundUpdateScrollState = this.updateScrollState.bind(this);
  }

  ngOnInit() {
    this.scrollService.registerScrollHandler(this.boundUpdateScrollState);
  }

  updateScrollState(offset: number) {
    this.currentOffset = offset;
    this.showButton = this.currentOffset > this.offsetDistance
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

}
