import { Component } from '@angular/core';
import { WebSocketService } from 'src/services';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-wechat',
  templateUrl: './wechat.component.html',
  styleUrls: ['./wechat.component.scss']
})
export class WechatAuthComponent {

  constructor(
    private webSocketService: WebSocketService,
  ) { }

  ngOnInit() {
    this.checkWxLoginCode();
  }

  private codeGotSubscription?: Subscription;

  ngOnDestroy() {
    this.disconnect();
  }

  disconnect() {
    if (!this.codeGotSubscription) return;
    this.codeGotSubscription.unsubscribe();
    this.webSocketService.disconnectSocket();
  }

  checkWxLoginCode() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    if (!state || !code) return;
    this.webSocketService.connectSocket();
    this.webSocketService.clientRequest(state);
    this.codeGotSubscription = this.webSocketService.onCodeGot().subscribe(data => window.close());
    this.webSocketService.sendRequest("sendCode", state, code);
  }
}
