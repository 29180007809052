<div class="software-group">
    <div class="corner corner-left-top"></div>
    <div class="corner corner-right-top"></div>
    <div class="corner corner-left-bottom"></div>
    <div class="corner corner-right-bottom"></div>
    <div class="software-intro vertical-center flex">
        <div class="soft-logo" [ngClass]="logoClass"></div>
        <p class="headline-1">{{headlineOne}}</p>
        <p class="headline-2">{{headlineTwo}}</p>
        <div class="line"></div>
        <button class="using" [ngClass]="buttonUseable?'using-enable':'using-disable'" [disabled]="!buttonUseable"
            mat-raised-button (click)="onButtonClick()">{{buttonText}}</button>
        <ng-container *ngIf="headlineThree">
            <p class="headline-3 with-arrow no-select" (click)="onButtonClick()">{{headlineThree}}</p>
        </ng-container>
    </div>
</div>