import { Component } from '@angular/core';
import { UserInfoService } from 'src/services';
import { UserInfo } from 'src/models';
import { Subscription } from 'rxjs';

interface MenuItem {
  icon: string;
  label: string;
  activeIcon:string
}

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent {

  private subscriptions: Subscription[] = [];

  activeMenuItem: number = 0; // 默认选中第一个菜单项
  menuItems: MenuItem[] = [
    { icon: '../../assets/images/icon/wallet-off.svg', label: '我的钱包', activeIcon: '../../assets/images/icon/wallet-on.svg' },
    { icon: '../../assets/images/icon/purchase-off.svg', label: '购买记录', activeIcon: '../../assets/images/icon/purchase-on.svg' },
    { icon: '../../assets/images/icon/personal-off.svg', label: '我的信息', activeIcon: '../../assets/images/icon/personal-on.svg' },
    { icon: '../../assets/images/icon/security-off.svg', label: '账号安全', activeIcon: '../../assets/images/icon/security-on.svg' }
  ];
  userinfo: UserInfo | null = null;

  constructor(
    private userInfoService: UserInfoService
  ){}

  ngOnInit(){
    this.subscriptions.push(
      this.userInfoService.userInfo$.subscribe((info) => {
        this.userinfo = info;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  setActive(itemIndex: number) {
    this.activeMenuItem = itemIndex;
  }

}
