<div class="login-container">
  <h1 class="company-title">欢迎回来~</h1>
  <form [formGroup]="loginForm" (ngSubmit)="login()" style="width:100%" class="login-form">

    <div class="loading-animation" *ngIf="loading">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div>

    <mat-form-field appearance="outline">
      <input matInput type="text" name="email" class="custom-input" formControlName="email" required autocomplete="off"
        placeholder="请输入邮箱">
      <mat-error *ngIf="loginForm.get('email')?.hasError('required')">邮箱地址是必填项</mat-error>
      <mat-error *ngIf="loginForm.get('email')?.hasError('email')">请输入有效的邮箱地址</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input matInput [type]="hide() ? 'password' : 'text'" name="password" formControlName="password"
        placeholder="请输入密码" required autocomplete="off" />
      <mat-hint align="end">
        <a>忘记密码?</a>
      </mat-hint>
      <button matSuffix (click)="passwordVisible($event)" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide()" class="hide-password-button" type="button">
        <ng-container *ngIf="hide();else visibility_outline">
          <div class="visibility_off_outline visibility-icon"></div>
        </ng-container>
        <ng-template #visibility_outline>
          <div class="visibility_outline visibility-icon"></div>
        </ng-template>
      </button>
      <mat-error *ngIf="loginForm.get('password')?.hasError('required')">密码是必填项</mat-error>
      <mat-error *ngIf="loginForm.get('password')?.hasError('minlength')">密码长度至少为6个字符</mat-error>
    </mat-form-field>

    <div class="checkbox-area">
      <div class="agreement">
        <mat-checkbox matRippleDisabled="true" formControlName="agreeToPrivacy">
          <p class="agreement-check">阅读并同意</p>
        </mat-checkbox>
        <!-- <p>隐私政策</p> -->
        <a (click)="openPrivacyPage()">隐私政策</a>
        <mat-error *ngIf="loginForm.get('agreeToPrivacy')?.hasError('requiredTrue')">
          请阅读并同意隐私政策
        </mat-error>
      </div>

      <div class="agreement">
        <mat-checkbox matRippleDisabled="true" formControlName="rememberMe">
          <!-- 记住我 -->
          <p class="agreement-check" >记住我</p>
        </mat-checkbox>
      </div>
    </div>

    <div class="button-container">
      <button mat-raised-button color="primary" type="submit" [disabled]="loginForm.invalid">登录</button>
    </div>
  </form>

  <div class="toggle-page">
    <span>没有账户？</span><a (click)="switchToRegister()">注册</a>
  </div>
</div>