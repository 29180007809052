import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl
const socketConfig = {
  path: '/socket.io',
  transports: ['websocket'], // 使用 websocket 作为传输方式
  withCredentials: true
}

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  private socket: Socket | null = null;

  constructor() { }

  // 初始化 socket 连接
  connectSocket(): void {
    if (!this.socket) this.socket = io(apiUrl, socketConfig);
  }

  // 断开 socket 连接
  disconnectSocket(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  // 加入订单房间
  joinOrderRoom(orderId: string): void {
    if (this.socket) this.socket.emit('joinOrderRoom', orderId);
  }

  // 监听订单状态更新
  onOrderStatusUpdate(): Observable<any> {
    return new Observable((observer) => {
      if (this.socket) this.socket.on('orderStatusUpdate', (data) => observer.next(data))
    });
  }

  // 加入监听客户端
  clientRequest(client_id: string): void {
    if (this.socket) this.socket.emit('clientRequest', client_id);
  }

  // 监听code更新
  onCodeGot(): Observable<any> {
    return new Observable((observer) => {
      if (!this.socket) return;
      this.socket.on('codeGot', (data) => observer.next(data))
    });
  }

  sendRequest(event: string, client_id: string, content: any) {
    if (this.socket) this.socket.emit(event, client_id, content);
  }

}
