import { Component, Renderer2 } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ScreenService } from 'src/services/cdk/screen.service'
import { ApiService } from 'src/services/api/api.service';
import { SnackbarService } from 'src/services/material/snackbar.service';
import { UtilService } from 'src/services/util/util.service';

import { Res } from '../../models';

@Component({
  selector: 'app-trial',
  templateUrl: './trial.component.html',
  styleUrls: ['./trial.component.scss']
})
export class TrialComponent {

  screenType: string = "desktop";
  loading: boolean = false;
  private subscriptions: Subscription[] = [];

  contactForm = new FormGroup({
    name: new FormControl('', Validators.required),
    company: new FormControl('', Validators.required),
    companyPosition: new FormControl('', Validators.required),
    companyAddress: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [Validators.required, Validators.pattern('[0-9]{11}')]),
    printer: new FormControl('', Validators.required),
    rip: new FormControl('', Validators.required),
    content: new FormControl('', Validators.required)
  });

  constructor(
    private screenService: ScreenService, 
    private renderer: Renderer2,
    private apiService: ApiService,
    private snackbarService: SnackbarService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.subscriptions.push(
      this.screenService.onResize$.subscribe(size => {
        this.screenType = size < 1200 ? "mobile" : "desktop";
        if (this.screenType === "mobile") {
          this.renderer.setStyle(document.body, 'background', '#F0F2FE fixed auto');
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.renderer.removeStyle(document.body, 'background');
  }

  
  submitForm() {
    if (!this.contactForm.valid) return;
    this.createInquiry();
  }

  async createInquiry() {
    const { name, phone, email } = this.contactForm.value;
    const { sanitizeInput } = this.utilService;
    const body = {
      user_name: sanitizeInput(name!),
      phone_number: sanitizeInput(phone!),
      email: sanitizeInput(email!),
      content: sanitizeInput(JSON.stringify(this.contactForm.value)!)
    };
    await this.withLoading(async () => {
      const res: Res = await this.apiService["createInquiry"]({ ...body });
      if (res.status !== 'success') throw res;
      this.snackbarService.open("发送成功"); // 使用服务
      this.resetForm();
    });
  }

  resetForm() {
    this.contactForm.reset();
    Object.keys(this.contactForm.controls).forEach(control => {
        const ctrl = this.contactForm.get(control);
        ctrl?.markAsPristine();
        ctrl?.markAsUntouched();
        ctrl?.setErrors(null);  // 清除所有错误
    });
  }

  async withLoading(asyncFunc: () => Promise<any>) {
    try {
      this.loading = true;
      await asyncFunc();
    } catch (error: any) {
      this.snackbarService.open("发送失败"); // 使用服务
    } finally {
      this.loading = false;
    }
  }

}
