<div class="flex vertical-center" style="position: relative;">
    <canvas #qrCanvas class="qrCanvas"></canvas>
    <div *ngIf="!orderId" class="placeholder placeholder-image">
        <button mat-raised-button (click)="generatePayCode()" class="refresh-button center flex">
            <ng-container *ngIf="loading">
                <mat-spinner [diameter]="20" class="white-spinner"></mat-spinner>
            </ng-container>
            <ng-container *ngIf="!loading">
                <span>请点击刷新</span>
            </ng-container>
        </button>
    </div>

    <div class="placeholder placeholder-back" *ngIf="tradeStatus=='TRADE_SUCCESS'">
        <div class="center">
            <p class="status-info">支付成功</p>
            <div class="success-icon"></div>
        </div>
    </div>

</div>

<button mat-raised-button (click)="refunds()" [disabled]="!orderId">退款</button>