import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { StorageService } from '../../services'

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class AuthInterceptorModule implements HttpInterceptor {

  constructor(
    private storage: StorageService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.storage.getItem("token");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Shanghai"
    if (token) { // 如果有token，就添加
      req = req.clone({
        setHeaders: {
          Authorization: '' + token,
          "TIME-ZONE": timeZone
        }
      });
    }

    return next.handle(req).pipe(
      tap({
        next: event => {

        },
        error: error => { // 统一处理所有的http错误
          // console.log(error);
          // console.log(error.error.message)
        }
      })
    )
  }
}
