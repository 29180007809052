import { Component } from '@angular/core';

@Component({
  selector: 'app-service-term',
  templateUrl: './service-term.component.html',
  styleUrls: ['./service-term.component.scss']
})
export class ServiceTermComponent {

//   yourMarkdownString: string = `# <center>**服务条款**</center>

// 欢迎访问由广州华瀚智能科技合伙企业（以下简称“我们”或“公司”）运营的官方网站（以下简称“本网站”）。请您在使用本网站及其服务前，仔细阅读以下条款。访问、注册及使用本网站即表示您同意遵守本服务条款。

// ## 一、服务简介

// 本网站为用户提供打印行业相关软件的在线服务。我们保留随时更新或调整服务内容的权利，且无需提前通知用户。更新后的条款一经发布即刻生效，用户继续使用本网站即表示接受变更内容。

// ## 二、用户注册

// 用户必须年满 18 周岁并具有完全民事行为能力，方可注册并使用本网站服务。未成年人使用本网站服务需征得法定监护人的同意。用户须提供准确、完整的信息用于注册，因提供虚假信息造成的损失由用户自行承担。

// ## 三、使用规范

// 用户不得利用本网站从事任何违反法律法规或侵犯他人合法权益的行为，包括但不限于发布违法内容、滥用网站资源等。用户应妥善保管账户及密码，因其操作或授权他人使用账户导致的损失由用户自行承担。我们有权对任何滥用网站的行为采取警告、封禁账户等措施。

// ## 四、知识产权

// 本网站所有内容（包括但不限于图文、软件、页面设计等）均归公司所有，受相关知识产权法律保护。用户在使用本网站服务时上传的原创内容，版权归用户所有。用户同意授予公司非独占性许可，用于提供、改进和推广相关服务。

// ## 五、免责声明

// 公司将尽力确保本网站服务的持续、安全与稳定，但不对以下情况导致的损失承担责任：
// - 不可抗力（例如自然灾害、战争等）；
// - 用户个人疏忽导致的信息泄露；
// - 第三方原因导致的服务中断或数据丢失。

// ## 六、责任限制

// 对于用户因网站技术、服务变更等因素产生的间接损失，若非公司原因，均不承担赔偿责任。公司保留对有害行为或涉嫌违法的行为采取措施的权利。

// ## 七、数据及隐私保护

// 我们重视用户的隐私保护，并已在隐私政策中详细说明数据收集、存储及保护措施。用户在使用本网站过程中须遵循隐私政策。

// ## 八、服务的终止

// 用户可随时申请注销账户，注销后将无法恢复，账户数据可能被清除。如用户严重违反服务条款，或因不可抗力导致服务无法继续，公司有权中止或终止服务。

// ## 九、法律适用与争议解决

// 本服务条款的解释、适用及相关争议，均适用中华人民共和国法律。
// 因使用本网站服务所产生的争议，应尽量协商解决；协商不成时，双方同意提交至本公司所在地人民法院解决。
//   `


yourMarkdownString: string = `# <center>**Terms of Service**</center>

Welcome to the official website operated by Guangzhou Huahan Intelligent Technology Partnership (hereinafter referred to as "we" or "the company"). Please read the following terms carefully before using our website and its services. By accessing, registering, and using this website, you agree to comply with these terms of service.

## 1. Service Overview

This website provides online services related to the printing industry. We reserve the right to update or adjust service content at any time without prior notice to users. Updated terms will take effect immediately upon publication, and continued use of the website indicates acceptance of the changes.

## 2. User Registration

Users must be at least 18 years old and possess full civil capacity to register and use the services of this website. Minors must obtain consent from their legal guardians to use the services. Users must provide accurate and complete information during registration; any losses caused by providing false information will be borne by the user.

## 3. Usage Guidelines

Users shall not use this website to engage in any illegal activities or infringe upon the legitimate rights of others, including but not limited to publishing illegal content and abusing website resources. Users are responsible for maintaining the security of their accounts and passwords and shall not transfer or authorize others to use their accounts. We reserve the right to take measures such as warnings or account bans against any abusive behavior.

## 4. Intellectual Property

All content on this website (including but not limited to text, images, software, page designs, etc.) is owned by the company and protected by relevant intellectual property laws. Users may not copy, modify, or distribute website content without authorization. Copyright for user-uploaded original content remains with the user, who grants the company a non-exclusive license for providing, improving, and promoting related services.

## 5. Disclaimer

The company will make efforts to ensure the continuity, safety, and stability of the services; however, it is not responsible for losses resulting from:
- Force majeure (such as natural disasters, war, etc.);
- User negligence leading to information leakage;
- Service interruptions or data losses due to third parties.

## 6. Limitation of Liability

The company is not liable for any indirect losses incurred by users due to technical issues, service changes, or other factors not caused by the company. The company reserves the right to take action against harmful or illegal behavior.

## 7. Data and Privacy Protection

We value user privacy and have detailed data collection, storage, and protection measures in our privacy policy. Users must comply with the privacy policy during registration and use of this website.

## 8. Termination of Services

Users may request to cancel their accounts at any time, and once canceled, the account will be irrecoverable, and account data may be deleted. If users seriously violate the terms of service or if force majeure prevents the continuation of services, the company reserves the right to suspend or terminate services without liability.

## 9. Legal Applicability and Dispute Resolution

These terms of service are governed by the laws of the People's Republic of China. Disputes arising from the use of this website's services should be resolved through negotiation; if negotiation fails, both parties agree to submit the dispute to the Guangzhou Arbitration Committee for arbitration.

  `

}
