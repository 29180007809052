import { Component, ViewEncapsulation, Input, Output, EventEmitter  } from '@angular/core';

@Component({
  selector: 'app-software-card',
  templateUrl: './software-card.component.html',
  styleUrls: ['./software-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SoftwareCardComponent {

  @Input() headlineOne?: string;
  @Input() headlineTwo?: string;
  @Input() headlineThree?: string;
  @Input() buttonText?: string;
  @Input() buttonUseable?: boolean;
  @Input() logoClass?: string;

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  onButtonClick() {
    // if (this.buttonUseable) {
    //   this.buttonClicked.emit();
    // }
    this.buttonClicked.emit();

  }

}
