import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ComponentType } from '@angular/cdk/overlay';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  private isOverlayOpenSubject = new BehaviorSubject<string>('');
  isOverlayOpen$ = this.isOverlayOpenSubject.asObservable();
  public overlayRef?: OverlayRef;
  constructor(private overlay: Overlay) { }

  setConfig(config: OverlayConfig = {}): OverlayRef {
    return this.overlay.create(config);
  }

  open(name: string, content: ComponentType<any> | TemplateRef<any>, config: OverlayConfig = {}, viewContainerRef?: ViewContainerRef) {
    if(this.overlayRef) this.overlayRef.dispose();
    this.overlayRef = this.setConfig(config);
    const portal = (content instanceof TemplateRef)
      ? new TemplatePortal(content, viewContainerRef!)
      : new ComponentPortal(content);
    this.overlayRef.attach(portal);
    this.isOverlayOpenSubject.next(name);
  }

  close() {
    if (!this.overlayRef) return;
    this.overlayRef.dispose();
    this.isOverlayOpenSubject.next('');
  }

  handleClickOutside(event: MouseEvent) {
    if (this.overlayRef && !this.overlayRef.overlayElement.contains(event.target as Node)) {
      this.close();
    }
  }
  
}
