<div class="back">
    <ng-container *ngIf="screenType=='desktop'">
        <div class="circle-bg"></div>
    </ng-container>
    <app-sakura></app-sakura>

    <div class="summary">
        <div class="summary-content">
            <div class="summary-title">
                <p class="headline-1">
                    RIP色彩管理，专业打印软件
                </p>
                <p class="headline-3">
                    将在计算机中制作完成的数字页面的各种图形、像等信息在输出设备形成能够记录点阵信息。
                </p>
                <p class="headline-4">
                    <button class="using-button" mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)"
                        (click)="openTrialPage()">申请试用</button>
                    <button class="contact-button" mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)"
                        (click)="openContactPage()">
                        <div class="flex-button">
                            <span>联系我们</span>
                            <img src="../../assets/images/porcelain/button-arrow.svg" alt="">
                        </div>
                    </button>
                </p>
                <div class="headline-5">
                    <video preload="auto" playsinline autoplay muted loop
                        src="../../assets/videos/porcelain/interface.mp4"></video>
                </div>
            </div>
        </div>
    </div>
</div>

<div [ngClass]="introBackClassName" class="intro-group">
    <ng-container *ngFor="let data of introData;let i = index">
        <div [ngClass]="'intro-'+(i+1)" class="intro">
            <div class="intro-area">
                <div class="intro-mask-back">
                    <div class="video-back">
                        <video autoplay muted loop preload="auto" playsinline
                            [src]="'../../assets/videos/porcelain/function-'+(i+1)+'.mp4'"
                            [poster]="'../../assets/images/porcelain/poster-'+(i+1)+'.png'"></video>
                    </div>
                </div>
                <div class="intro-content">
                    <p class="headline-1">
                        {{data.headtext1}}
                    </p>
                    <p class="headline-2">
                        {{data.headtext2}}
                    </p>
                    <p class="headline-3">
                        <button class="font-size-large using-button" mat-raised-button matRipple
                            matRippleColor="rgba(186, 221, 255, 0.5)" (click)="openTrialPage()">申请试用</button>
                    </p>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="intro intro-4">
        <div class="switch-area">
            <p class="switch-title">
                light/dark 模式，只为了打造更好的用户体验
            </p>
            <app-image-comparison></app-image-comparison>
        </div>
    </div>
</div>

<div class="reason">
    <div class="reason-area">
        <div class="headline-1">
            <div class="logo"></div>
            <p>为什么选择青华？</p>
        </div>

        <div class="headline-2">
            <p>助力每一位用户将业务提升到新的高度</p>
            <p>在印刷行业中，效率和精确度是至关重要的，而青华正是为满足这些需求而设计的终极解决方案。</p>
        </div>

        <div class="headline-3 flex">
            <ng-container *ngFor="let adv of advData;let i = index">

                <div class="adv-card" #advCard (mouseenter)="onMouseEnter(i, $event)" (mouseleave)="onMouseLeave(i)"
                    (mousemove)="onMouseMove($event, i)">

                    <div class="light-ball" *ngIf="adv.isHovered"
                        [ngStyle]="{'transform': 'translate3d(' + adv.position.x + 'px, ' + adv.position.y + 'px, 0)'}">
                    </div>
                    <img [src]="'../../assets/images/porcelain/adv-'+(i+1)+'.svg'" alt="">
                    <p>{{adv.headtext1}}</p>
                    <p>{{adv.headtext2}}</p>
                </div>
            </ng-container>
        </div>

    </div>
</div>

<div class="scheme">
    <div class="scheme-area">
        <div class="scheme-content">
            <div class="scheme-text">
                <p>获取专属定制方案</p>
                <p>想要提升印刷效率和质量？立即联系我们，了解如何通过我们的RIP排版打印软件实现您的业务目标！</p>
            </div>
            <p class="scheme-button">
                <button class="download-button" mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)"
                    (click)="openTrialPage()">
                    申请试用
                </button>
                <button class="using-button" mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)"
                    (click)="openContactPage()">
                    <div class="flex-button">
                        <span>联系我们</span>
                        <img src="../../assets/images/porcelain/button-arrow.svg" alt="">
                    </div>
                </button>
            </p>
        </div>
        <div class="dashed-line"></div>
    </div>

</div>