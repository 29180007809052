import { Injectable } from '@angular/core';
import { fromEvent, BehaviorSubject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  private resizeSubject = new BehaviorSubject(getInitialWidth());

  get onResize$() {
    return this.resizeSubject.asObservable();
  }

  constructor() {
    if (typeof window !== 'undefined') {
      fromEvent(window, 'resize')
        .pipe(
          debounceTime(200), // Optional: debounce rapid events
          map(event => (event.target as Window).innerWidth)
        )
        .subscribe(this.resizeSubject);
    }
  }
}

function getInitialWidth(): number {
  // Replace `window.innerWidth` with your default width for SSR
  return typeof window !== 'undefined' ? window.innerWidth : 800; // 800 is just an example
}
