import { Component, EventEmitter, Output, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ApiService, SnackbarService, StorageService } from 'src/services';
import { Res } from 'src/models';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  hide = signal(true);
  @Output() switchPage = new EventEmitter<string>();
  loading: boolean = false;
  countdown: number = 0; // 倒计时
  isButtonDisabled: boolean = false; // 按钮禁用状态
  readonly VERIFICATION_TIMEOUT = 300; // 验证码等待时间，单位：秒

  // 定义 registerForm
  registerForm = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6)
    ]),
    verificationCode: new FormControl('', [
      Validators.required  // 验证码必填
    ]),
    agreeToPrivacy: new FormControl(false, [
      Validators.requiredTrue  // 必须为 true 才会通过验证
    ])
  });

  constructor(
    private apiService: ApiService,
    private snackbarService: SnackbarService,
    private storage: StorageService,
  ) { }

  ngOnInit() {
    // 页面加载时检查是否有上次获取验证码的时间
    const lastRequestTime = this.storage.getItem('lastVerificationTime');
    if (lastRequestTime) {
      const timeElapsed = Math.floor((Date.now() - parseInt(lastRequestTime, 10)) / 1000);
      if (timeElapsed < this.VERIFICATION_TIMEOUT) {
        this.startCountdown(this.VERIFICATION_TIMEOUT - timeElapsed);
      }
    }
  }

  // 切换密码显示/隐藏
  passwordVisible(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }

  async getVerificationCode() {
    const emailControl = this.registerForm.get('email');

    // 检查邮箱是否为空或无效
    if (!emailControl?.value) return this.snackbarService.open('请输入邮箱地址以获取验证码');
    if (emailControl.invalid) return this.snackbarService.open('请输入有效的邮箱地址');
    const { email } = this.registerForm.value;

    const body = {
      "contact_info": email,
      "purpose": "register"
    }

    await this.withLoading(async () => {
      let res: Res = await this.apiService["getCaptcha"]({ ...body });
      if (res.status !== 'success') throw res;
      this.snackbarService.open('验证码已发送，请查收');
      const now = Date.now().toString();
      this.storage.setItem('lastVerificationTime', now);
      this.startCountdown(this.VERIFICATION_TIMEOUT);
    });

  }

  // 开始倒计时
  startCountdown(seconds: number) {
    this.countdown = seconds;
    this.isButtonDisabled = true;
    const intervalId = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(intervalId);
        this.isButtonDisabled = false;
      }
    }, 1000);
  }

  // 提交注册表单
  async register() {
    if (!this.registerForm.valid) return;
    const { email, password, verificationCode } = this.registerForm.value;
    const body = {
      contact_info: email,
      password: password,
      verification_code: verificationCode
    }
    await this.withLoading(async () => {
      let res: Res = await this.apiService["register"]({ ...body });
      if (res.status !== 'success') throw res;
      this.snackbarService.open("注册成功"); // 使用服务
      this.switchToLogin();
    });
  }

  switchToLogin() {
    this.switchPage.emit('login');
  }

  async withLoading(asyncFunc: () => Promise<any>) {
    try {
      this.loading = true;
      await asyncFunc();
    } catch (error: any) {
      this.snackbarService.open("something went wrong!"); // 使用服务
    } finally {
      this.loading = false;
    }
  }

}
