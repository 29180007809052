<div class="placeholder-back"></div>

<div class="contact">

    <div class="contact-header">
        <p class="contact-title">请与我们联系</p>
    </div>

    <div class="contact-content">

        <div class="contact-message">

            <div class="contact-tip">
                <p>给我们留言</p>
                <p>请给我们留言，我们会尽快的回复您。</p>
            </div>

            <form class="example-form" [formGroup]="contactForm" (ngSubmit)="submitForm()">
                <div class="loading-animation" *ngIf="loading">
                    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                </div>

                <div class="combine-form-control">
                    <mat-form-field class="example-full-width">
                        <mat-label>{{ 'Name' | translate }}</mat-label>
                        <input matInput name="name" formControlName="name">
                        <mat-error *ngIf="contactForm.get('name')?.hasError('required')">{{ 'Contact.name-required' |
                            translate }}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="example-full-width">
                        <mat-label>{{ 'CompanyName' |
                            translate }}</mat-label>
                        <input matInput name="company" formControlName="company">
                        <mat-error *ngIf="contactForm.get('company')?.hasError('required')">{{
                            'Contact.company-required' |
                            translate }}</mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'Phone' | translate }}</mat-label>
                    <input matInput name="phone" formControlName="phone">
                    <mat-error
                        *ngIf="contactForm.get('phone')?.hasError('pattern')||contactForm.get('phone')?.hasError('required')">{{
                        'Contact.phonenumber-required' |
                        translate }}</mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width">
                    <mat-label>{{ 'Email' | translate }}</mat-label>
                    <input matInput name="email" formControlName="email">
                    <mat-error
                        *ngIf="contactForm.get('email')?.hasError('email')||contactForm.get('email')?.hasError('required')">{{
                        'Contact.email-required' |
                        translate
                        }}</mat-error>
                </mat-form-field>
                <mat-form-field class="example-full-width contact-message-textarea">
                    <mat-label>{{ 'Contact.Content' | translate }}</mat-label>
                    <textarea matInput name="content" formControlName="content"
                        class="contact-message-textarea-input"></textarea>
                    <mat-error *ngIf="contactForm.get('content')?.hasError('required')">{{ 'Contact.content-required' |
                        translate
                        }}</mat-error>
                </mat-form-field>
                <button class="font-size-large example-full-width " mat-raised-button color="primary">{{ 'submit' |
                    translate }}</button>
            </form>
        </div>

        <div class="contact-manner">
            <div class="contact-manner-worktime contact-manner-box">
                <img [src]="'/assets/images/phone.svg'" alt="">
                <p>{{ '+ 199 2846 8799' | translate }}</p>
            </div>

            <div class="contact-manner-email contact-manner-box">
                <img [src]="'/assets/images/mail.svg'" alt="">
                <p>{{"support@eprint.ink"}}</p>
            </div>

            <div class="contact-manner-address contact-manner-box">
                <img [src]="'/assets/images/address.svg'" alt="">
                <p>{{ 'Contact.Address' | translate }}</p>
            </div>
        </div>

    </div>
</div>