import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {

//   yourMarkdownString: string = `# <center>**隐私权条款**</center>

// 非常欢迎您光临「华瀚公司官方网站」（以下简称本网站），为了让您能够安心使用本网站的各项服务与资讯，特此向您说明本网站的隐私权保护政策，以保障您的权益，请您详阅下列内容：

// ### 一、隐私权保护政策的适用范围

// 隐私权保护政策内容，包括本网站如何处理在您使用网站服务时收集到的个人识别资料。隐私权保护政策不适用于本网站以外的相关连结网站，也不适用于非本网站所委託或参与管理的人员。

// ### 二、个人资料的蒐集、处理及利用方式

// - 当您造访本网站或使用本网站所提供之功能服务时，我们将视该服务功能性质，请您提供必要的个人资料，并在该特定目的范围内处理及利用您的个人资料；非经您书面同意，本网站不会将个人资料用于其他用途。
// - 本网站在您使用服务信箱、问卷调查等互动性功能时，会保留您所提供的姓名、电子邮件地址、联络方式及使用时间等。
// - 于一般浏览时，伺服器会自行记录相关行径，包括您使用连线设备的IP位址、使用时间、使用的浏览器、浏览及点选资料记录等，做为我们增进网站服务的参考依据，此记录为内部应用，决不对外公佈。
// - 为提供精确的服务，我们会将收集的问卷调查内容进行统计与分析，分析结果之统计数据或说明文字呈现，除供内部研究外，我们会视需要公佈统计数据及说明文字，但不涉及特定个人之资料。
// - 您可以随时向我们提出请求，以更正或删除您的帐户或本网站所蒐集的个人资料等隐私资讯。联繫方式请见最下方联繫管道。

// ### 三、资料之保护

// - 本网站主机均设有防火牆、防毒系统等相关的各项资讯安全设备及必要的安全防护措施，加以保护网站及您的个人资料採用严格的保护措施，只由经过授权的人员才能接触您的个人资料，相关处理人员皆签有保密合约，如有违反保密义务者，将会受到相关的法律处分。
// - 如因业务需要有必要委託其他单位提供服务时，本网站亦会严格要求其遵守保密义务，并且採取必要检查程序以确定其将确实遵守。

// ### 四、网站对外的相关连结

// 本网站的网页提供其他网站的网路连结，您也可经由本网站所提供的连结，点选进入其他网站。但该连结网站不适用本网站的隐私权保护政策，您必须参考该连结网站中的隐私权保护政策。

// ### 五、与第三人共用个人资料之政策

// 本网站绝不会提供、交换、出租或出售任何您的个人资料给其他个人、团体、私人企业或公务机关，但有法律依据或合约义务者，不在此限。

// 前项但书之情形包括不限于：

// - 经由您书面同意。
// - 法律明文规定。
// - 为免除您生命、身体、自由或财产上之危险。
// - 与公务机关或学术研究机构合作，基于公共利益为统计或学术研究而有必要，且资料经过提供者处理或蒐集着依其揭露方式无从识别特定之当事人。
// - 当您在网站的行为，违反服务条款或可能损害或妨碍网站与其他使用者权益或导致任何人遭受损害时，经网站管理单位研析揭露您的个人资料是为了辨识、联络或採取法律行动所必要者。
// - 有利于您的权益。

// 本网站委託厂商协助蒐集、处理或利用您的个人资料时，将对委外厂商或个人善尽监督管理之责。

// ### 六、Cookie之使用

// 为了提供您最佳的服务，本网站会在您的电脑中放置并取用我们的Cookie，若您不愿接受Cookie的写入，您可在您使用的浏览器功能项中设定隐私权等级为高，即可拒绝Cookie的写入，但可能会导至网站某些功能无法正常执行 。

// ### 七、隐私权保护政策之修正

// 本网站隐私权保护政策将因应需求随时进行修正，修正后的条款将刊登于网站上。

// ### 八、联繫管道

// 对于本站之隐私权政策有任何疑问，或者想提出变更、移除个人资料之请求，请前往本站「联络我们」页面提交表单。或藉由以下资讯联繫我们:

// - 公司名称: 广州华瀚智能科技合伙企业
// - 地址: 广州市黄埔区联和街道锦昊智谷B区B209
// - Email: support@eprint.ink
// - 电话: +86 199 2846 8799
// `

yourMarkdownString: string = `# <center>**Privacy Policy**</center>

## 1. Scope of Application

This policy applies to the website services operated by Guangzhou Huahan Intelligent Technology Partnership (hereinafter referred to as "we"). We will reasonably, necessarily, and legally protect and utilize the personal information you provide during use. If users access third-party websites through links, the privacy policies of those third parties will apply.

## 2. Collection, Processing, and Use of Personal Data

1. **Information Collection Scenarios**
   The types of personal information we may collect include:
   - User account registration information (name, email, phone number, WeChat identity information, etc.)
   - Device information (IP address, browsing history, usage time, browser, etc.)
   - Contact information and usage time provided by users during interaction with the website.

2. **Information Use**
   The user data we collect is used for:
   - Service delivery and optimization (including account access and personalized services)
   - Data analysis and statistics (based on anonymized data)

3. **Use of Cookies**
   To optimize your access experience, we will store and use cookies. If you do not wish to accept this, please adjust your browser privacy settings, but it may lead to some functions not working properly.

## 3. Data Protection Measures

We take reasonable technical measures, including HTTPS protocol, information encryption, firewalls, and antivirus systems, to ensure data security. Authorized personnel and partner vendors will strictly adhere to confidentiality agreements, and any violations will bear corresponding legal responsibilities.

## 4. Data Sharing

We will not share, exchange, rent, or sell user personal data without user written consent, except for:
- Obtaining user written authorization;
- Legal or government directives;
- Legitimate necessity for public safety and safeguarding the rights of the website.

## 5. User Rights

Users can request:
- Access to and update their account information;
- Account cancellation or deletion of certain data records (if deletion is not possible due to orders or system records, the data will be archived).

## 6. Privacy Policy Revisions

We may revise this policy due to business needs and publish it on this page. Continued use will be regarded as acceptance of the updated terms.

## 7. Contact Us

If you have questions or wish to correct or delete personal information, please contact:
- **Company Name**: Guangzhou Huahan Intelligent Technology Partnership
- **Address**: B209, Jinhao Zhigu B Area, Lianhe Street, Huangpu District, Guangzhou
- **Email**: support@eprint.ink
- **Phone**: +86 199 2846 8799

`

}
