<footer class="flex vertical-center">
  <div class="link-area flex">
    <div (click)="navigateToHome()">
      <div class="logo"></div>
      <p class="company-desc">专业的智能打印一体化平台</p>
    </div>

    <ng-container *ngIf="screenType=='desktop';else accordion">
      <div class="company-link link">
        <p class="link-title">公司</p>
        <p>关于我们</p>
        <p>为什么选择我们</p>
        <p>定价</p>
      </div>
      <div class="resource-link link">
        <p class="link-title">资源</p>
        <p>隐私政策</p>
        <p>条款和条件</p>
        <p>联系我们</p>
      </div>
      <div class="product-link link">
        <p class="link-title">产品</p>
        <p>青华—Porcelain</p>
        <p>丹书—Instrument</p>
        <p>丰芒—Abundance</p>
        <p>玄玉—Brilliance</p>
      </div>
    </ng-container>

    <ng-template #accordion>

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>公司</mat-panel-title>
          </mat-expansion-panel-header>
          <p>关于我们</p>
          <p>为什么选择我们</p>
          <p>定价</p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>资源</mat-panel-title>
          </mat-expansion-panel-header>
          <p>隐私政策</p>
          <p>条款和条件</p>
          <p>联系我们</p>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>产品</mat-panel-title>
          </mat-expansion-panel-header>
          <p>青华—Porcelain</p>
          <p>丹书—Instrument</p>
          <p>丰芒—Abundance</p>
          <p>玄玉—Brilliance</p>
        </mat-expansion-panel>
      </mat-accordion>

    </ng-template>


  </div>

  <div class="license">
    <div class="flex license-area">
      <div class="line left-line"></div>
      <p>Copyright © 2024.All Rights Reserved.华瀚公司 版权所有</p>
      <div class="line right-line"></div>
    </div>

    <p class="record"><a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">粤ICP备2023078277号</a>
    </p>
  </div>


</footer>