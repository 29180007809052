import { Component, ViewChild, ElementRef } from '@angular/core';
import { ScreenService } from 'src/services/cdk/screen.service'
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';  // 引入 Router

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {

  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;
  isPlaying: boolean = false; // 控制视频是否正在播放

  private subscriptions: Subscription[] = [];
  screenType: string = "desktop";

  constructor(
    private screenService: ScreenService,
    private router: Router  // 注入 Router
  ) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.screenService.onResize$.subscribe(size => {
        this.screenType = size < 1200 ? "mobile" : "desktop";
      })
    );
  }

  ngAfterViewInit() {
    // 监听视频播放状态
    const video = this.videoPlayer.nativeElement;
    video.addEventListener('play', () => this.isPlaying = true);
    video.addEventListener('pause', () => { this.isPlaying = false; });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    // 清理事件监听器
    const video = this.videoPlayer.nativeElement;
    video.removeEventListener('play', () => this.isPlaying = true);
    video.removeEventListener('pause', () => this.isPlaying = false);
  }

  handleButtonClick() {
    this.router.navigate(['/porcelain']);  // 跳转到 'porcelian' 路由
  }

  playVideo(event:any) {
    event.preventDefault();
    this.isPlaying ? this.videoPlayer.nativeElement.pause() : this.videoPlayer.nativeElement.play();
  }

}