<div class="flex modal">

    <div class="flex-2 modal-left">
        <div class="circle-bg circle-bg-left"></div>
        <div class="circle-bg circle-bg-right"></div>
        <div class="logo"></div>
        <div class="purpose">
            <p class="headline-1">为客户创造提升价值</p>
            <p class="headline-2">致力于工业打印一体化服务~</p>
        </div>
        <div class="printer-image"></div>
    </div>

    <div class="flex-3 modal-right">
        <app-wxlogin *ngIf="currentPage === 'wxlogin'"></app-wxlogin>
        <app-login *ngIf="currentPage === 'login'" (switchPage)="setCurrentPage($event)"></app-login>
        <app-register *ngIf="currentPage === 'register'" (switchPage)="setCurrentPage($event)"></app-register>
    </div>
</div>

<div class="change-box-tips-wrap">
    <div class="change-box-tips-content">
        <div class="change-box-tips">
            <div class="ed-svg-icon change-box-tips-code wechat-svg" (click)="wxlogin()" *ngIf="currentPage === 'login'"></div>

            <!-- <div class="ed-svg-icon change-box-tips-code wechat-svg" (click)="setCurrentPage('wxlogin')" *ngIf="currentPage === 'login'"></div> -->
            <div class="ed-svg-icon change-box-tips-code mail-svg" (click)="setCurrentPage('login')" *ngIf="currentPage === 'wxlogin'"></div>
        </div>
    </div>
</div>