<header
    [ngClass]="[this.pageIndex === 0 ? 'normal-header' : 'scrollable-header',(overlayRefName=='menu' || isScrolled)?'scrolled':'']">
    <div class="list">
        <div class="logo"
            [ngClass]="[pageTheme[pageIndex].logoClassName,(overlayRefName=='menu' || isScrolled) ? 'scrolled' : '']"
            [routerLink]="pageTheme[pageIndex].link"></div>

        <ng-container *ngIf="screenType=='desktop'">
            <div class="navlist-left flex">
                <app-menu-trigger [template]="productTemplate" [positionStrategy]="overlayStrategy" name="product">
                    <span [ngClass]="[
                        (pageIndex === 0 || overlayRefName=='menu' || isScrolled) ? 'dark-font' : 'light-font'
                    ]">产品</span>
                    <mat-icon fontIcon="expand_more" class="arrow-toggle-icon" [ngClass]="[
                        (pageIndex === 0 || overlayRefName=='menu' || isScrolled) ? 'dark-font' : 'light-font',
                        overlayRefName=='product' ? 'rotated' : ''
                        ]"></mat-icon>
                </app-menu-trigger>
                <app-menu-trigger>
                    <span routerLink="/help" [ngClass]="[
                        (pageIndex === 0 || overlayRefName=='menu' || isScrolled) ? 'dark-font' : 'light-font'
                        ]">帮助中心</span>
                </app-menu-trigger>
                <app-menu-trigger>
                    <span routerLink="/contact" [ngClass]="[
                        (pageIndex === 0 || overlayRefName=='menu' || isScrolled) ? 'dark-font' : 'light-font'
                        ]">联系我们</span>
                </app-menu-trigger>
            </div>
            <div class="navlist-right flex">
                <button mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)" (click)="openTrialPage()"
                    [ngClass]="[pageIndex === 0 ? 'normal-button' : 'scrollable-button']">{{ '申请试用' | translate
                    }}</button>
                <ng-container *ngIf="userinfo; else loggedOut">
                    <app-menu-trigger [template]="personalTemplate" [positionStrategy]="overlayStrategy"
                        name="personal">
                        <img [src]="userinfo.avatar || 'https://eprint.ink/files/website/avatar/female-1.png'" alt=""
                            class="avatar">
                    </app-menu-trigger>
                </ng-container>
                <ng-template #loggedOut>
                    <button class="font-size-large" mat-raised-button (click)="openLoginDialog()">
                        {{ 'Header.login' | translate }}
                    </button>
                    <!-- <app-googlelogin></app-googlelogin> -->
                </ng-template>

            </div>
        </ng-container>

        <ng-container *ngIf="screenType!='desktop'">
            <app-menu-trigger [template]="navListTemplate" [positionStrategy]="dropdownMenuStrategy" name="menu">
                <mat-icon [ngClass]="[
                        (pageIndex === 0 || overlayRefName=='menu' || isScrolled) ? 'dark-font' : 'light-font'
                        ]">{{overlayRefName=='menu'?"close":"menu"}}</mat-icon>
            </app-menu-trigger>
        </ng-container>
    </div>
</header>

<ng-template #navListTemplate>
    <div class="nav-overlay">
        <div class="navlist flex">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>产品</mat-panel-title>
                    </mat-expansion-panel-header>
                    <app-product-dropdownmenu></app-product-dropdownmenu>
                </mat-expansion-panel>
            </mat-accordion>
            <a class="link-title" routerLink="/help" (click)="closeOverlay()">帮助中心</a>
            <a class="link-title" routerLink="/contact" (click)="closeOverlay()">联系我们</a>
            <button class="free-start" mat-raised-button (click)="closeOverlay();openTrialPage()">申请试用</button>
        </div>
    </div>
</ng-template>

<ng-template #productTemplate>
    <app-product-dropdownmenu></app-product-dropdownmenu>
</ng-template>

<ng-template #personalTemplate>
    <app-profile [@fadeInOut]="true"></app-profile>
</ng-template>