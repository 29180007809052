import { Component, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent {

  isExpanded = false;
  contentMaxHeight = '0px';

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  togglePanel() {
    const content = this.el.nativeElement.querySelector('.expansion-panel-content');
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded) {
      this.renderer.setStyle(content, 'max-height', `${content.scrollHeight}px`);
    } else {
      this.renderer.setStyle(content, 'max-height', '0px');
    }
  }

}
