import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './../pages/home/home.component'
import { ContentComponent } from './../components/content/content.component'
import { PriceComponent } from 'src/pages/price/price.component';
import { ContactComponent } from './../pages/contact/contact.component'
import { HelpComponent } from '../pages/help/help.component'
import { PorcelainComponent } from '../pages/porcelain/porcelain.component'
import { TrialComponent } from '../pages/trial/trial.component'
import { PersonalComponent } from 'src/pages/personal/personal.component';
import { PrivacyComponent } from 'src/pages/privacy/privacy.component';
import { ServiceTermComponent } from 'src/pages/service-term/service-term.component';
import { QtWebasmComponent } from 'src/components/qt-webasm/qt-webasm.component';

import { WechatAuthComponent } from 'src/pages/oauth/authorized/wechat/wechat.component';

const routes: Routes = [
  {
    path: "", component: HomeComponent, children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      { path: "home", component: ContentComponent },
      { path: "price", component: PriceComponent },
      { path: "contact", component: ContactComponent },
      { path: "help", component: HelpComponent },
      { path: "porcelain", component: PorcelainComponent },
      { path: "personal", component: PersonalComponent },
      { path: "privacy", component: PrivacyComponent },
      { path: "term-services", component: ServiceTermComponent },
      { path: "web", component: QtWebasmComponent },
    ]
  },
  { path: "trial", component: TrialComponent },
  { path: "oauth/authorized/wechat", component: WechatAuthComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
