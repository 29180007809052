import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent {

  @Input() options: any[] = []; // 选项列表
  @Input() selectedIndex: number | null = null; // 默认选中的索引
  @Input() template: any; // 外部模板
  @Output() selectionChange = new EventEmitter<{ index: number; value: any }>(); // 通知外部

  selectOption(option: any, index: number) {
    if (this.selectedIndex !== index) {
      this.selectedIndex = index; // 更新选中索引
      const selectedValue = option.value; // 获取选中值
      this.selectionChange.emit({ index, value: selectedValue }); // 发射索引和选中值
    }
  }
  
}
