import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ScrollService, ScreenService, OverlayService, SnackbarService, UserInfoService } from 'src/services';
import { ConnectedPosition } from '@angular/cdk/overlay';

import { Subscription } from 'rxjs';
import { AuthComponent } from 'src/pages/auth/auth.component';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserInfo } from 'src/models';
import { fadeInOut } from 'src/animation/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [fadeInOut]
})
export class HeaderComponent implements OnDestroy {

  menuVisible: boolean = false;
  imgPrefix = "/assets/images/"
  isScrolled: boolean = false;
  private subscriptions: Subscription[] = [];
  screenType: string = "desktop";
  logoColor: string = "white"
  overlayStrategy: ConnectedPosition = { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 24 }
  dropdownMenuStrategy: ConnectedPosition = { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', offsetY: 18 }
  pageTheme = [
    { logoClassName: "huahan-logo", link: "/" },
    // { logoClassName: "porcelain-logo", link: "/porcelain" }
    { logoClassName: "huahan-logo", link: "/" }
  ]
  userinfo: UserInfo | null = null;
  userAssets: any;
  userCoin: number = 0;
  pageIndex = 1;
  private boundUpdateScrollState: (offset: number) => void;
  loading: boolean = false;
  isRefreshing: boolean = false;
  overlayRefName:string = "";

  constructor(
    private dialog: MatDialog,
    private scrollService: ScrollService,
    private screenService: ScreenService,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private userInfoService: UserInfoService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.subscriptions.push(
      // this.router.events.subscribe(event => {
      //   if (event instanceof NavigationEnd) this.checkRoute();
      // }),
      this.overlayService.isOverlayOpen$.subscribe(overlayRefName => this.overlayRefName = overlayRefName)
    );
    this.boundUpdateScrollState = this.updateScrollState.bind(this);
  }

  async ngOnInit() {
    this.subscriptions.push(
      this.screenService.onResize$.subscribe(size => {
        this.screenType = size < 1200 ? "mobile" : "desktop";
      }),
      this.userInfoService.userInfo$.subscribe((info) => {
        this.userinfo = info;
      })
    );
    this.scrollService.registerScrollHandler(this.boundUpdateScrollState);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.scrollService.unregisterScrollHandler(this.boundUpdateScrollState);
  }

  updateScrollState(offset: number) {
    this.isScrolled = offset > (this.screenType == "desktop" ? 80 : 72);
    this.cdr.detectChanges(); // 手动触发变更检测
  }

  checkRoute() {
    const urlSegments = this.router.url.split('/');
    const secondLevelUrl = urlSegments[1];
    switch (secondLevelUrl) {
      case 'porcelain':
        this.pageIndex = 1;
        break;
      default:
        this.pageIndex = 0;
        break;
    }
    this.cdr.detectChanges(); // 手动触发变更检测
  }

  openLoginDialog(): void {
    const dialogRef = this.dialog.open(AuthComponent, {
      width: '720px',
      height: '486px',
      maxWidth: '100vw',
      panelClass: "my-dialog"
    });

    dialogRef.afterClosed().subscribe(result => { });
  }

  openTrialPage() {
    this.router.navigate(['/trial']);  // 跳转到 'trial' 路由
  }

  closeOverlay() {
    this.overlayService.close();
  }

  async withLoading(asyncFunc: () => Promise<any>) {
    try {
      this.loading = true;
      await asyncFunc();
    } catch (error: any) {
      console.log(error)
      this.snackbarService.open("something went wrong!"); // 使用服务
    } finally {
      this.loading = false;
    }
  }

}

// switchLanguage() {
//   const currentLang = this.translate.currentLang || this.translate.defaultLang;
//   if (currentLang === 'zh') {
//     this.translate.use('en');
//     localStorage.setItem('app_language', 'en');
//   } else {
//     this.translate.use('zh');
//     localStorage.setItem('app_language', 'zh');
//   }
// }