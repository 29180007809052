import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService, StorageService } from 'src/services';
import { UserInfo } from 'src/models';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  private userInfoSubject = new BehaviorSubject<UserInfo | null>(null);
  userInfo$ = this.userInfoSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private storage: StorageService
  ) {}

  async fetchAndSetUserInfo(): Promise<void> {
    const token = this.storage.getItem("token");
    if(!token) return;
    const res = await this.apiService["getUserInfo"]();
    if (res.status !== 'success') return console.log("not login");
    this.userInfoSubject.next(res.result);
  }

  getUserInfo(): UserInfo | null {
    return this.userInfoSubject.getValue();
  }

  clearUserInfo(): void {
    this.userInfoSubject.next(null);
  }
}
