import { Component, Input, OnInit } from '@angular/core';
import { marked } from 'marked';

@Component({
  selector: 'app-markdown-renderer',
  templateUrl: './markdown-renderer.component.html',
  styleUrls: ['./markdown-renderer.component.scss']
})
export class MarkdownRendererComponent {

  @Input() markdownContent: string = '';
  renderedMarkdown: string = '';

  ngOnInit(): void {
    this.renderedMarkdown = marked(this.markdownContent);
  }
}
