export const VFnArr: Array<{ [s: string]: string }> = [
    //inquiry
    { name: 'createInquiry', method: 'post', prefix: "inquiry", path: 'create_inquiry' },
    //userinfo
    { name: 'getUserInfo', method: 'get', prefix: "user_info", path: 'get_user_info' },
    { name: 'getUserAssets', method: 'get', prefix: "user_info", path: 'get_user_assets' },
    //user
    { name: 'logout', method: 'get', prefix: "user", path: 'logout' },
    //asset
    { name: 'getAssets', method: 'get', prefix: "asset", path: 'get_assets' },
    //order
    { name: 'createPayPalOrder', method: 'post', prefix: "order", path: 'create_paypal_order' },
    { name: 'capturePayPalOrder', method: 'post', prefix: "order", path: 'capture_paypal_order' },

    { name: 'createWechatOrder', method: 'post', prefix: "order", path: 'create_wechat_order' },
    { name: 'captureWechatOrder', method: 'post', prefix: "order", path: 'capture_wechat_order' },
    { name: 'refunds', method: 'post', prefix: "order", path: 'refunds' },
    { name: 'checkWechatOrderStatus', method: 'post', prefix: "order", path: 'check_wechat_order_status' },

    { name: 'createAliOrder', method: 'post', prefix: "order", path: 'create_ali_order' },
    { name: 'captureAliOrder', method: 'post', prefix: "order", path: 'capture_ali_order' },
    { name: 'checkAliOrderStatus', method: 'post', prefix: "order", path: 'check_ali_order_status' },

    { name: 'payDirectly', method: 'post', prefix: "order", path: 'pay_directly' },
    { name: 'deleteOrder', method: 'delete', prefix: "order", path: 'delete_order' },
];
