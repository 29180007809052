import { Component } from '@angular/core';

import { ApiService, SnackbarService } from 'src/services';
import { Res } from 'src/models';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent {

  productRadioOptions = [
    { label: '￥ 1', value: 1, price: 0.01, quantity: 100, id: 1, type: 1 },
    { label: '￥ 10', value: 10, price: 0.01, quantity: 100, id: 1, type: 1 },
    { label: '￥ 100', value: 100, price: 0.01, quantity: 100, id: 1, type: 1 },
    { label: '￥ ', value: null, price: 0.01, quantity: 100, isCustom: true, id: 1, type: 1 },
    { label: '青华大会员', value: 1, price: 99, quantity: 1, id: 2, type: 2 },
  ];

  paymentRadioOptions = [
    { label: '微信支付', value: 1, icon: "wechat-pay" },
    { label: '支付宝支付', value: 2, icon: "ali-pay" },
    { label: 'PayPal支付', value: 3, icon: "paypal-pay" },
    { label: 'H币支付', value: 4, icon: "h-coin" },
  ];

  checked: boolean = false
  selectedProductIndex: number = 0; // 选中价格索引
  selectedPaymentIndex: number | null = 0; // 选中支付方式索引
  loading: boolean = false;
  hcoinObject?: any
  selectedProduct: any = this.productRadioOptions[this.selectedProductIndex]
  cart: any[] = [];

  constructor(
    private apiService: ApiService,
    private snackbarService: SnackbarService,
  ) { }

  onProductSelectionChange(selectedProductIndex: number, newValue: any) {
    // console.log('当前选中的数量是:', newValue, '选中索引是:', selectedProductIndex);
    this.selectedProductIndex = selectedProductIndex; // 更新选中索引
    this.selectedProduct = this.productRadioOptions[selectedProductIndex];
    this.updateCart();
  }

  onPaymentSelectionChange(selectedPaymentIndex: number, newValue: any) {
    // console.log('当前选中的支付方式是:', newValue, '选中索引是:', selectedPaymentIndex);
    this.selectedPaymentIndex = selectedPaymentIndex; // 更新选中索引
    this.updateCart();
  }

  onCustomPriceInputChange(option: any) {
    const value = option.value;
    // 检查输入值是否为有效数字且大于等于1
    if (value === null || isNaN(value) || value < 1 || value % 1 !== 0) {
      // 输入无效，清空输入框
      setTimeout(() => {
        option.value = null; // 清空选项的值
      }, 0);
    } else {
      // 输入有效
      const selectedProductIndex = this.productRadioOptions.indexOf(option);
      this.onProductSelectionChange(selectedProductIndex, value); // 更新选中的金额和索引
    }
  }

  ngOnInit() {
    this.getAssets();
  }

  async getAssets() {
    await this.withLoading(async () => {
      let res: Res = await this.apiService["getAssets"]({ name: "H币" });
      if (res.status !== 'success') throw res;
      this.hcoinObject = res.result[0];
      this.updateCart();
    });
  }

  updateCart() {
    const { type, id, quantity, price, value } = this.selectedProduct;
    this.cart = [{ type, id, quantity: quantity * (value || 1), price }];
  }

  async withLoading(asyncFunc: () => Promise<any>) {
    try {
      this.loading = true;
      await asyncFunc();
    } catch (error: any) {
      this.snackbarService.open(error.message || "something went worng!"); // 使用服务
    } finally {
      this.loading = false;
    }
  }

  async payDirectly() {
    // console.log(this.cart)
    await this.withLoading(async () => {
      let res: Res = await this.apiService["payDirectly"]({ cart: this.cart });
      if (res.status !== 'success') throw res;
      this.snackbarService.open(res.message); // 使用服务
    });
  }

}
