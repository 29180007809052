// animations.ts
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
    state('void', style({ opacity: 0 })),
    transition('void => *', animate('300ms', keyframes([
        style({ opacity: 0, offset: 0 }),
        style({ opacity: 1, offset: 1 })
    ]))),
    transition('* => void', animate('300ms', keyframes([
        style({ opacity: 1, offset: 0 }),
        style({ opacity: 0, offset: 1 })
    ])))
]);
