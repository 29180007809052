import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-image-comparison',
  templateUrl: './image-comparison.component.html',
  styleUrls: ['./image-comparison.component.scss']
})
export class ImageComparisonComponent {
  sliderValue: number = 70; // 初始化滑动条的值为70%

  // 根据 sliderValue 返回裁剪样式
  getClipPath(): string {
    return `inset(0px ${100 - this.sliderValue}% 0px 0px)`; // 使用 inset 来设置裁剪区域
  }

  // 处理鼠标移动
  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    event.stopPropagation();
    const container = event.currentTarget as HTMLElement;
    const containerWidth = container.clientWidth;
    let offsetX = event.offsetX;
  
    // 确保值在0到100之间
    if (offsetX < 0) {
      offsetX = 0; // 如果小于0，设置为0
    } else if (offsetX > containerWidth) {
      offsetX = containerWidth; // 如果大于容器宽度，设置为容器宽度
    }
  
    this.sliderValue = (offsetX / containerWidth) * 100; // 计算滑块值
  }

  onMouseLeave(event: MouseEvent) {
    const container = event.currentTarget as HTMLElement;
    const offsetX = event.offsetX;
  
    // 从左边离开
    if (offsetX <= 0) {
      this.sliderValue = 0; // 设置为左边界值
    } 
    // 从右边离开
    else if (offsetX >= container.clientWidth) {
      this.sliderValue = 100; // 设置为右边界值
    }
  }
  
}
