import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  sanitizeInput(input: string): string {
    // 进行输入过滤和转义处理，确保输入内容安全
    // 可以使用相应的过滤函数或正则表达式来移除或转义特殊字符
    const sanitizedInput = input.replace(/[<>&]/g, (match) => {
      switch (match) {
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        case '&':
          return '&amp;';
        default:
          return match;
      }
    });
    return sanitizedInput;
  }

  generateRandomString() {
    var date = new Date();
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 1).toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    var randomDigits = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
    var randomString = year + month + day + randomDigits;
    return randomString;
  }

}
