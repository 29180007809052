import { Component } from '@angular/core';

import { StorageService, OverlayService, ApiService, SnackbarService, UserInfoService } from 'src/services';
import { Res, UserInfo } from 'src/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {

  userinfo: UserInfo | null = null;
  private subscriptions: Subscription[] = [];

  userCoin: number = 0;
  isRefreshing: boolean = false;
  loading: boolean = false;

  constructor(
    private apiService: ApiService,
    private storage: StorageService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private userInfoService: UserInfoService
  ) {
  }

  ngOnInit() {
    this.getCoin();
    this.subscriptions.push(
      this.userInfoService.userInfo$.subscribe((info) => {
        this.userinfo = info;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  async getCoin() {
    const res = await this.apiService["getUserAssets"]();
    if (res.status !== 'success') throw res;
    this.storage.setItem('userAssets', res.result);
    const userAssets: any = res.result;
    if (!userAssets) return;
    userAssets.forEach((ele: any) => {
      if (ele.name == "H币") this.userCoin = ele.quantity
    })
  }

  closeOverlay() {
    this.overlayService.close();
  }

  async logout() {
    await this.withLoading(async () => {
      let res: Res = await this.apiService["logout"]();
      this.snackbarService.open("退出登录"); // 使用服务
      this.storage.removeItem('token');
      this.storage.removeItem('qt-v0-HuaHan-Instrument-Web/token');
      this.userInfoService.clearUserInfo();
      this.closeOverlay();
    });
  }

  async withLoading(asyncFunc: () => Promise<any>) {
    try {
      this.loading = true;
      await asyncFunc();
    } catch (error: any) {
      console.log(error)
      this.snackbarService.open("something went wrong!"); // 使用服务
    } finally {
      this.loading = false;
    }
  }

}
