import { Component } from '@angular/core';
import { ScrollService } from "src/services/cdk/scroll.service"
import { ScreenService } from 'src/services/cdk/screen.service'
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-porcelain',
  templateUrl: './porcelain.component.html',
  styleUrls: ['./porcelain.component.scss']
})
export class PorcelainComponent {

  introData = [
    {
      "headtext1": "高效智能排版",
      "headtext2": "能够自动调整和对齐设计元素，轻松处理复杂的图案布局。您只需拖放操作，软件便会智能优化排版，自动对齐、分布和调整图层，使每一件印刷品都达到完美的视觉效果。内置的布局模板进一步简化设计过程，让您快速开始，无需从头设计。无论是批量生产还是个性化定制，智能排版功能都能显著提升工作效率，减少人为错误，帮助您在竞争激烈的市场中保持领先。"
    },
    {
      "headtext1": "专属白色通道",
      "headtext2": "在印刷行业中，专属白色通道是实现高质量印刷效果的关键工具。青华提供了先进的白色通道处理功能，专为需要在深色或透明介质上印刷白色图案而设计。白色通道允许您在图像中单独处理白色部分，确保每一笔白色都具有精准的覆盖和鲜明的对比。这不仅提高了图像的层次感和细节表现，还确保了在任何材质上的色彩准确再现，特别是在高要求的时尚和广告印刷中。通过专业的白色通道管理，您可以轻松实现设计意图，避免色彩混淆或不均，确保每一件印刷品都展现出卓越的视觉效果。"
    },
    {
      "headtext1": "线性化测量方案",
      "headtext2": "在高质量印刷生产中，线性化测量是确保色彩一致性和图像准确性的核心技术。青华配备了先进的线性化测量功能，能够精确调整和校正打印机的色彩输出，以实现最佳的印刷效果。线性化测量通过对色彩响应曲线进行细致分析，确保每种颜色在不同的打印强度下保持一致，减少色彩偏差和打印误差。通过精确的线性化调整，您不仅能获得更加逼真的色彩和图像，还能提高生产效率，降低废品率，确保每一件印刷品都符合最高质量标准。"
    },
  ]

  advData = [
    {
      "headtext1": "高效的色彩管理",
      "headtext2": "色彩一致性对于印刷质量至关重要。软件的色彩管理系统可以精确控制色彩输出，支持多种色彩配置和校准选项，从而保证每次印刷都能完美再现。",
      position: { x: 0, y: 0 },
      isHovered: false
    },
    {
      "headtext1": "智能排版功能",
      "headtext2": "青华软件-具备智能排版工具，可以轻松处理复杂的排版任务。支持拖放操作、自动对齐、和自定义布局，让您能够快速高效地完成设计。",
      position: { x: 0, y: 0 },
      isHovered: false
    },
    {
      "headtext1": "实时预览与调整",
      "headtext2": "在印刷前，软件提供实时预览功能，允许您查看最终效果并进行必要的调整。确保每个设计细节都符合您的要求，避免不必要的浪费和修正。",
      position: { x: 0, y: 0 },
      isHovered: false
    },
    {
      "headtext1": "支持多图导入",
      "headtext2": "可一次载入多个图片任务(大小、格式不同)进行调整缩放及排列布局，对行业用户而言，可极大的缩短排版时间，非常有效的提高了工作效率。",
      position: { x: 0, y: 0 },
      isHovered: false
    },
    {
      "headtext1": "全面的图像处理能力",
      "headtext2": "青华软件提供强大的图像处理功能，从图像优化到色彩管理，确保每一个打印项目都能呈现出卓越的效果。使得您的每一件印刷品都能达到高质量标准。",
      position: { x: 0, y: 0 },
      isHovered: false
    },
    {
      "headtext1": "全面的技术支持",
      "headtext2": "提供全方位的技术支持服务，包括详细的操作手册、在线帮助和技术支持。无论您在使用过程中遇到什么问题，我们都将为您提供专业的解答和支持。",
      position: { x: 0, y: 0 },
      isHovered: false
    }
  ]

  private boundUpdateScrollState: (offset: number) => void;
  private subscriptions: Subscription[] = [];
  screenType: string = "desktop";

  constructor(
    private scrollService: ScrollService,
    private screenService: ScreenService,
    private router: Router
  ) {
    this.boundUpdateScrollState = this.updateScrollState.bind(this);
  }

  updateScrollState(offset: number) {
    this.currentOffset = offset;
  }

  ngOnInit() {
    this.scrollService.registerScrollHandler(this.boundUpdateScrollState);
    this.subscriptions.push(
      this.screenService.onResize$.subscribe(size => {
        this.screenType = size < 1200 ? "mobile" : "desktop";
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private currentOffset: number = 0;

  get introBackClassName(): string {
    // 根据 offset 返回不同的 class 名称
    const offsetHeight = (this.screenType=="desktop")? 1300 : 1000
    // const offset = (this.screenType=="desktop")? 840 : 667;
    const offset =  840;
    const classNameList = [
      'intro-back-1', 'intro-back-2', 'intro-back-3', 'intro-back-4'
    ]
    if (this.currentOffset < offsetHeight) {
      return classNameList[0];
    } else if (this.currentOffset >= offsetHeight && this.currentOffset < offsetHeight + offset) {
      return classNameList[1];
    } else if (this.currentOffset >= offsetHeight + offset && this.currentOffset < offsetHeight + offset * 2) {
      return classNameList[2];
    }
    return classNameList[3];
  }

  private animationFrameId: number | null = null;
  private lightBallSize = 50; // 光球尺寸

  onMouseEnter(index: number, event: MouseEvent) {
    const cardElement = event.currentTarget as HTMLElement;
    const cardRect = cardElement.getBoundingClientRect();

    // 计算 padding 值
    const paddingX = parseFloat(getComputedStyle(cardElement).paddingLeft) + parseFloat(getComputedStyle(cardElement).paddingRight);
    const paddingY = parseFloat(getComputedStyle(cardElement).paddingTop) + parseFloat(getComputedStyle(cardElement).paddingBottom);

    // 更新光球位置
    this.advData[index].position.x = event.clientX - cardRect.left - (this.lightBallSize / 2) - (paddingX / 2);
    this.advData[index].position.y = event.clientY - cardRect.top - (this.lightBallSize / 2) - (paddingY / 2);

    // 位置更新后再显示光球
    this.advData[index].isHovered = true;
  }

  onMouseMove(event: MouseEvent, index: number) {
    const cardElement = event.currentTarget as HTMLElement;
    if (!cardElement) return;
  
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  
    this.animationFrameId = requestAnimationFrame(() => {
      const cardRect = cardElement.getBoundingClientRect();
      const paddingX = parseFloat(getComputedStyle(cardElement).paddingLeft) + parseFloat(getComputedStyle(cardElement).paddingRight);
      const paddingY = parseFloat(getComputedStyle(cardElement).paddingTop) + parseFloat(getComputedStyle(cardElement).paddingBottom);
      
      // 计算光球位置
      this.advData[index].position.x = event.clientX - cardRect.left - (this.lightBallSize / 2) - (paddingX / 2);
      this.advData[index].position.y = event.clientY - cardRect.top - (this.lightBallSize / 2) - (paddingY / 2);
      
      // 修正旋转角度计算
      const multiple = 50;
      const calcX = (event.clientY - cardRect.top - cardRect.height / 2) / multiple;
      const calcY = -(event.clientX - cardRect.left - cardRect.width / 2) / multiple;
      // 应用旋转效果
      cardElement.style.transform = `rotateX(${calcX}deg) rotateY(${calcY}deg)`;
    });
  }
  
  onMouseLeave(index: number) {
    // 取消正在进行的动画帧
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
    // 重置旋转角度并添加过渡效果
    const cardElement = document.querySelectorAll('.adv-card')[index] as HTMLElement;
    if (cardElement) {
      cardElement.style.transform = 'rotateX(0) rotateY(0)';
    }
    this.advData[index].isHovered = false;
  }

  openTrialPage(){
    this.router.navigate(['/trial']);  // 跳转到 'trial' 路由
  }

  openContactPage(){
    this.router.navigate(['/contact']);  // 跳转到 'trial' 路由
  }

}
