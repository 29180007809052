<mat-card appearance="outlined" [@fadeInOut]="true">
    <mat-card-content>
        <mat-nav-list>
            <ng-container *ngFor="let navItem of navData">
                <mat-list-item>
                    <div class="software-background flex" [routerLink]="navItem.link" (click)="closeOverlay()">
                        <div class="software-group flex">
                            <div class="software-logo" [ngClass]="navItem.className"></div>
                            <div class="software-intro">
                                <a>{{navItem.headlineTextOne}}</a>
                                <a>{{navItem.headlineTextTwo}}</a>
                            </div>
                        </div>
                        <mat-icon class="icon-right">chevron_right</mat-icon>
                    </div>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>
    </mat-card-content>
</mat-card>