import { Component, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';

import * as QRCode from 'qrcode';
import { ApiService, SnackbarService, WebSocketService } from 'src/services';

import { Subscription, Observable, interval, takeWhile, switchMap } from 'rxjs';

@Component({
  selector: 'app-ali',
  templateUrl: './ali.component.html',
  styleUrls: ['./ali.component.scss']
})
export class AliComponent {

  @ViewChild('qrCanvas', { static: false }) qrCanvas!: ElementRef;
  @Input() cart!: any[];  // 通过Input接收父组件传入的cart数组
  loading: boolean = false;
  orderId: string = ''
  tradeStatus: string = ''
  private scanStatusSubscription?: Subscription;
  private orderStatusSubscription?: Subscription;

  constructor(
    private apiService: ApiService,
    private snackbarService: SnackbarService,
    private webSocketService: WebSocketService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cart'] && !changes['cart'].firstChange) {
      this.orderId = "";
      this.tradeStatus = "";
    }
    this.disconnect();
  }

  ngOnDestroy() {
    this.disconnect();
  }

  disconnect() {
    if (this.orderStatusSubscription) {
      this.scanStatusSubscription?.unsubscribe();
      this.orderStatusSubscription.unsubscribe();
      this.webSocketService.disconnectSocket();
    }
  }

  async generatePayCode() {
    this.clearQRCode();  // 清除旧二维码
    if (!this.cart || this.cart.length == 0) return
    await this.withLoading(async () => {
      const res: any = await this.apiService["createAliOrder"]({ cart: this.cart });
      if (res.status != "success") throw res
      const { result, order_id } = res;
      this.orderId = order_id;
      this.generateQRCode(result);

      this.scanStatusSubscription = this.pollOrderStatus().subscribe();

      this.webSocketService.connectSocket();
      this.webSocketService.joinOrderRoom(this.orderId);
      this.orderStatusSubscription = this.webSocketService.onOrderStatusUpdate().subscribe(
        (data) => {
          if (data.orderId === this.orderId) {
            this.tradeStatus = data.status;
            if (data.status == "TRADE_SUCCESS") this.disconnect();
          }
        }
      );
    });
  }

  generateQRCode(text: string): void {
    var opts = { type: 'image/jpeg', width: 240, height: 240 }
    QRCode.toCanvas(this.qrCanvas.nativeElement, text, opts, (error: any) => {
      error ? console.error(error) : console.log('QR Code generated');
    });
  }

  // 轮询订单状态
  pollOrderStatus(maxDuration: number = 60000, intervalTime: number = 3000): Observable<any> {
    const startTime = Date.now();
    return interval(intervalTime).pipe(
      // 使用 switchMap 来替换 interval 的值，发出新的 HTTP 请求
      switchMap(() => this.checkOrderStatus()),
      // 使用 takeWhile 来限制轮询时间，或在订单状态成功时停止轮询
      takeWhile((response) => {
        const elapsed = Date.now() - startTime;
        const isTradeComplete = this.tradeStatus === 'WAIT_BUYER_PAY';
        return elapsed < maxDuration && !isTradeComplete;
      }, true)
    );
  }

  clearQRCode(): void {
    const canvas = this.qrCanvas.nativeElement as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    if (ctx) ctx.clearRect(0, 0, canvas.width, canvas.height);  // 清除 canvas 上的内容
  }

  async checkOrderStatus() {
    await this.withLoading(async () => {
      const res = await this.apiService["checkAliOrderStatus"]({ order_id: this.orderId, cart: this.cart });
      if (res.status !== "success") throw (res);
      const { code, tradeStatus } = res.result;
      if (code != "10000") return;
      this.tradeStatus = tradeStatus;
    });
  }

  async withLoading(asyncFunc: () => Promise<any>) {
    try {
      this.loading = true;
      await asyncFunc();
    } catch (error: any) {
      console.log(error)
      this.snackbarService.open(error.message || "something went worng!"); // 使用服务
    } finally {
      this.loading = false;
    }
  }

}
