import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { loadScript } from "@paypal/paypal-js";

import { ApiService, SnackbarService } from 'src/services';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent {

  @Input() cart!: any[];  // 通过Input接收父组件传入的cart数组
  @ViewChild('payButtonContainer', { static: true }) payButtonContainer?: ElementRef;

  constructor(
    private apiService: ApiService,
    private snackbarService: SnackbarService,
  ) { }

  ngAfterViewInit(): void {
    this.paypalButtonInit();  // 初始加载时调用
  }

  async paypalButtonInit() {
    let paypal;
    try {
      paypal = await loadScript({
        clientId: "AU7v7BYzSDZasVMFRTqfMs3MU3YhUwFzs340d9_KW0CJn6DcV8P383JYNyV_d2U0qj_hSbxx-5_C1F01",
        components: ["buttons", "marks", "messages"],
        dataPageType: "checkout",
      })
    } catch (error) {
      console.error("failed to load the PayPal JS SDK script", error);
    }

    if (!paypal || !paypal.Buttons) return;

    paypal!.Buttons({
      style: {
        layout: 'horizontal',
        color: 'silver',
        shape: 'rect',
        label: 'paypal',
        disableMaxWidth: true,
        tagline: false
      },
      displayOnly: ["vaultable"],
      createOrder: async (data: any, actions: any) => {
        if (!this.cart || this.cart.length === 0) return;
        try {
          const res: any = await this.apiService["createPayPalOrder"]({ cart: this.cart });
          if (res.status !== "success") throw (res.message);
          const orderData = res.result;
          if (orderData.id) return orderData.id;
          const errorDetail = orderData?.details?.[0];
          const errorMessage = errorDetail
            ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
            : JSON.stringify(orderData);
          throw new Error(errorMessage);
        } catch (error) {
          this.snackbarService.open(`Could not initiate PayPal Checkout...${error}`)
        }
      },
      onApprove: async (data: any, actions: any) => {
        // 支付成功逻辑
        try {
          const res: any = await this.apiService["capturePayPalOrder"]({ order_id: data.orderID, cart: this.cart });
          if (res.status !== "success") throw (res.message);
          const orderData = res.result;
          const errorDetail = orderData?.details?.[0];

          if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
            return actions.restart();
          } else if (errorDetail) {
            throw new Error(
              `${errorDetail.description} (${orderData.debug_id})`
            );
          } else if (!orderData.purchase_units) {
            throw new Error(JSON.stringify(orderData));
          } else {
            this.snackbarService.open(`支付成功，请查看你的钱包`)
          }
        } catch (error) {
          console.error(error);
          this.snackbarService.open(
            `Sorry, your transaction could not be processed...<br>${error}<br>`
          );
        }
      },
      onCancel: async (data: any, actions: any) => {
        // 取消支付逻辑
        const res: any = await this.apiService["deleteOrder"]({ id: data.orderID });
      },
      onError: async (error: any) => {
        console.log(`code worng...<br>${error}<br>`);
      }
    }).render(this.payButtonContainer!.nativeElement).catch((error: any) => {
      console.error("failed to render the PayPal Buttons", error);
    });
  }

}
