<div class="place-holding"></div>
<div class="headline-1">
  <div class="left-nav">
    <div class="personal-info">
      <img [src]="userinfo?.avatar||'https://eprint.ink/files/website/avatar/female-1.png'" alt="" class="avatar">
      <p>{{userinfo?.contact_person}}</p>
      <div class="coin">
        <img src="../../assets/images/h-coin.svg" alt="" class="h-coin">
        <p>H币：3000</p>
      </div>
    </div>

    <div class="nav-menu" cdkMenu>
      <button *ngFor="let item of menuItems; let i = index" class="contact-button example-menu-item" cdkMenuItem
        [ngClass]="{'active': activeMenuItem === i}" (click)="setActive(i)">
        <div class="flex-button">
          <img [src]="activeMenuItem === i ? item.activeIcon : item.icon" alt="">
          <span>{{ item.label }}</span>
        </div>
      </button>
    </div>

  </div>
  <div class="right-nav card">
    <ng-container [ngSwitch]="activeMenuItem">
      <ng-container *ngSwitchCase="0">
        <ng-template [ngTemplateOutlet]="walletTemplate"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="1">
        <ng-template [ngTemplateOutlet]="purchaseTemplate"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="2">
        <ng-template [ngTemplateOutlet]="personalTemplate"></ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="3">
        <ng-template [ngTemplateOutlet]="securityTemplate"></ng-template>
      </ng-container>
    </ng-container>
  </div>

</div>

<ng-template #walletTemplate>
  <div class="card-title">
    <div class="wallet">
      <p>账户余额</p>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="card-content">
    <div class="wallet">
      <div class="balance">
        <span>3000</span>
        <span>H币（100H币＝1元）</span>
      </div>
      <button mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)" routerLink="/price">{{ '立即充值' |
        translate }}</button>
    </div>

  </div>
</ng-template>

<ng-template #purchaseTemplate>
  <div class="card-title">
    <div class="purchase">
      <p>订单号</p>
      <p>订单详情</p>
      <p>订单时间</p>
      <p>金额</p>
      <p>电子发票</p>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="card-content">
    <div class="purchase">
      <p>暂无购买记录~</p>
      <button mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)" routerLink="/price">{{ '我要购买' |
        translate }}</button>
    </div>
  </div>
</ng-template>

<ng-template #personalTemplate>
  <div class="card-title">
    <div class="personal">
      <p>基本信息</p>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="card-content">
    <div class="personal">
      <mat-list>
        <mat-list-item>
          <div class="list-item">
            <img [src]="userinfo?.avatar||'https://eprint.ink/files/website/avatar/female-1.png'" alt="" class="avatar">
            <p>个人头像</p>
            <button mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)">{{ '修改' |
              translate }}</button>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <div class="list-item">
            <p>用户名</p>
            <p>{{userinfo?.contact_person}}</p>
            <button mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)">{{ '修改' |
              translate }}</button>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <div class="list-item">
            <p>电话</p>
            <p>199 **** 2561</p>
            <button mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)">{{ '修改' |
              translate }}</button>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <div class="list-item">
            <p>公司名称</p>
            <p>广州市星汉灿烂有限公司</p>
            <button mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)">{{ '修改' |
              translate }}</button>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <div class="list-item">
            <p>职位</p>
            <p class="item-center-content">技术人员</p>
            <button mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)">{{ '修改' |
              translate }}</button>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
    </div>
  </div>
</ng-template>

<ng-template #securityTemplate>
  <div class="card-title">
    <div class="security">
      <p>当前账号安全等级：</p>
      <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
      <p>低</p>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="card-content">
    <div class="security">
      <mat-list>
        <mat-list-item>
          <div class="list-item">
            <div class="tips-icon">
              <img src="../../assets/images/icon/success.svg" alt="">
              <span>登录密码</span>
            </div>
            <span>建议您设置较复杂的密码，可以提高账号的安全性</span>
            <button mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)">{{
              '修改密码' |
              translate }}</button>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div class="list-item">
            <div class="tips-icon">
              <img src="../../assets/images/icon/warning.svg" alt="">
              <span>绑定微信</span>
            </div>
            <span>验证后，可用于快速找回登录密码</span>
            <button mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)">{{
              '去绑定' |
              translate }}</button>
          </div>
        </mat-list-item>
        <mat-list-item>
          <div class="list-item">
            <div class="tips-icon">
              <img src="../../assets/images/icon/success.svg" alt="">
              <span>绑定邮箱</span>
            </div>
            <span>验证后，可用于快速找回登录密码</span>
            <button mat-raised-button matRipple matRippleColor="rgba(186, 221, 255, 0.2)" disabled="">{{
              '已绑定' |
              translate }}</button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</ng-template>