import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

import { FnArr } from '../fn';
import { VFnArr } from '../vfn';
import { Res } from '../../models';
import { environment } from 'src/environments/environment';

const apiUrl = environment.apiUrl + '/sdk-api/'; // 使用环境中的 apiUrl
const version = "v2";
// const apiUrl = 'http://localhost:3003/sdk-api/';
// const apiUrl = 'https://eprint.ink/sdk-api/';
const timeOutSetting = 30000;
const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  [key: string]: any;

  constructor(private http: HttpClient) {
    this.genFn();
  }

  genFn() {
    FnArr.forEach((fn: { [s: string]: string }) => {
      this[fn["name"]] = (params: object) => {
        return this.requestDeal(fn["method"], fn["prefix"] + '/' + fn["path"], params);
      };
    });
    VFnArr.forEach((fn: { [s: string]: string }) => {
      this[fn["name"]] = (params: object) => {
        return this.requestDeal(fn["method"], version + '/' + fn["prefix"] + '/' + fn["path"], params);
      };
    });
  }

  requestDeal(method: string, path: string, params: any) {
    switch (method) {
      case 'get':
        return this.getRequest(path, params);
      case 'put':
        return this.putRequest(path, params);
      case 'delete':
        return this.delRequest(path, params);
      default:
        return this.postRequest(path, params);
    }
  }

  private buildUrl(url: string, paramsObj: any): string {
    if (url.includes(':id')) {
      const idValue = paramsObj['id'];
      url = url.replace(':id', idValue);
      delete paramsObj['id']
    }
    return url;
  }

  async getRequest(url: string, paramsObj: any) {
    url = this.buildUrl(url, paramsObj);
    let params = this.genParams(paramsObj);
    let res: any;
    try {
      res = await firstValueFrom(
        this.http
          .get(apiUrl + url, { params, withCredentials: true, headers })
          .pipe(timeout(timeOutSetting))
      );
    } catch (err: any) {
      if(!environment.production) console.log(err)
      res = err.error || err;
    }
    return this.messageDeal(res);
  }

  async postRequest(url: string, paramsObj: object) {
    let params = this.paramsFilter(paramsObj);
    let res: any
    try {
      res = await firstValueFrom(
        this.http
          .post(apiUrl + url, params, { withCredentials: true, headers })
          .pipe(timeout(timeOutSetting))
      )
    } catch (err: any) {
      res = err.error || err
    }
    return this.messageDeal(res);
  }

  async putRequest(url: string, paramsObj: object) {
    let params = this.paramsFilter(paramsObj);
    if (url.includes(':id') && params.id) {
      url = url.replace(':id', params.id);
    }
    let splicingUrl = apiUrl + url;
    let res: any;
    try {
      res = await firstValueFrom(
        this.http
          .put(splicingUrl, params, { withCredentials: true, headers })
          .pipe(timeout(timeOutSetting))
      );
    } catch (err: any) {
      res = err.error || err;
    }
    return this.messageDeal(res);
  }

  async delRequest(url: string, paramsObj: object) {
    let params = this.paramsFilter(paramsObj);
    let res: any;
    try {
      res = await firstValueFrom(
        this.http
          .delete(apiUrl + url + "/" + params.id, { withCredentials: true, headers })
          .pipe(timeout(timeOutSetting))
      );
    } catch (err: any) {
      res = err.error || err;
    }
    return this.messageDeal(res);
  }

  messageDeal(res: Res) {
    try {
      if (!res.status || res.status != 'success') throw res;
    } catch (err: any) {
      res = { status: 'error', message: err.message };
    } finally {
      return Promise.resolve(res);
    }
  }

  genParams(paramsObj: object) {
    let params = new HttpParams();
    if (paramsObj) {
      let values = Object.values(paramsObj);
      let keys = Object.keys(paramsObj);
      values.forEach((ele: any | null, index) => {
        if (ele != null) {
          let key = keys[index];
          let value = ele;
          switch (key) {
            case 'pageIndex':
              key = 'page';
              break;
            case 'pageSize':
              key = 'size';
              break;
            case 'sortOrder':
              value =
                value == 'ascend' ? 'asc' : ele == 'descend' ? 'desc' : null;
              key = 'by';
              break;
            case 'sortField':
              key = 'order';
              break;
          }
          params = params.set(key, value);
        }
      });
    }
    return params;
  }

  paramsFilter(paramsObj: object) {
    let values = Object.values(paramsObj);
    let keys = Object.keys(paramsObj);
    let params: any = {};
    values.forEach((ele: any | null, index) => {
      if (ele != null) params[keys[index]] = ele;
    });
    return params;
  }

}
